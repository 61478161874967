import React, {FC, HTMLAttributes} from 'react';
import {cn} from '../lib/utils';

export const DefaultSpinner: React.FC = () => {
  return (
    <svg
      className="w-8 h-8 mr-3 -ml-1 text-blue-600 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const ButtonSpinner: React.FC = () => {
  return (
    <svg
      className="w-5 h-5 -ml-1 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const MiniSpinner: React.FC = () => {
  return (
    <svg
      className="w-3 h-3 mt-1 ml-1 mr-2 text-blue-600 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const SpinnerVariant = {
  default: DefaultSpinner,
  mini: MiniSpinner,
  button: ButtonSpinner,
};

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'mini' | 'button';
  centered?: boolean;
}

export const Loading: FC<LoadingProps> = ({
  variant = 'default',
  centered = true,
  className,
  ...props
}) => {
  const Spinner = SpinnerVariant[variant];
  return centered ? (
    <div
      className={cn(
        'flex justify-center items-center max-w-lg px-2 mx-auto',
        className
      )}
      role="status"
      {...props}
    >
      <Spinner />
    </div>
  ) : (
    <div role="status">
      <Spinner />
    </div>
  );
};
