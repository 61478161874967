import {useLocation, useNavigate} from 'react-router-dom';
import {FormConfigField} from 'payble-shared';
import {ZodSchema} from 'zod';
import {removeCmdkCurrentForm, setCmdkCurrentForm} from 'lib/cmdk/useCmdkState';

type Args = {
  title?: string;
  form: {
    schema?: ZodSchema;
    fields: FormConfigField[];
    withoutButton?: boolean;
  };
};

export type Action =
  | {
      type: 'navigate';
      path: string;
      state?: Record<string, any>;
      args?: Args;
    }
  | {
      type: 'navigate-open-drawer';
      path: string;
      state?: Record<string, any>;
      args?: Args;
      openDrawerId: string;
    }
  | {
      type: 'open-drawer';
      state?: Record<string, any>;
      openDrawerId: string;
    };

const replacePathParams = (path: string, context?: Record<string, any>) => {
  if (!context) return path;
  return path.replace(/:(\w+)/g, (_, key) => context[key] ?? `:${key}`);
};

export const useExecuteAction = (context?: Record<string, any>) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (command: Action) => {
    switch (command.type) {
      case 'navigate': {
        const path = replacePathParams(command.path, context);

        if (command.args?.form) {
          setCmdkCurrentForm({
            title: command.args.title,
            ...command.args.form,
            onSubmit: values => {
              const finalPath = replacePathParams(path, values);
              navigate(finalPath, {
                state: {...command.state},
              });
              removeCmdkCurrentForm();
            },
          });
        } else {
          navigate(path, {
            state: {...command.state},
          });
        }
        break;
      }
      case 'navigate-open-drawer': {
        const path = replacePathParams(command.path, context);

        if (command.args?.form) {
          setCmdkCurrentForm({
            title: command.args.title,
            ...command.args.form,
            onSubmit: values => {
              const finalPath = replacePathParams(path, values);
              navigate(finalPath, {
                state: {...command.state, openDrawerId: command.openDrawerId},
              });
              removeCmdkCurrentForm();
            },
          });
        } else {
          navigate(path, {
            state: {...command.state, openDrawerId: command.openDrawerId},
          });
        }
        break;
      }
      case 'open-drawer':
        navigate(location.pathname, {
          state: {
            ...command.state,
            openDrawerId: command.openDrawerId,
          },
        });
        break;
      default:
        break;
    }
  };
};
