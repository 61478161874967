import {Form, useFormContext} from 'payble-ui';
import {classNames} from '../lib/styles';
import {FormInputNumberFormatProps} from 'payble-ui/src/components/FormInputNumberFormat';

export const DirectDebitAUAccountNumberField = (
  props: Pick<
    FormInputNumberFormatProps,
    'size' | 'className' | 'label' | '_label'
  >
) => {
  const context = useFormContext();

  return (
    <div className="flex-1">
      <Form.InputNumberFormat
        format="#########"
        placeholder="000000000"
        name="accountNumber"
        id="accountNumber"
        label={props.label}
        _label={props._label}
        className={props.className ? classNames(props.className) : undefined}
        autoComplete={'off'}
        size={props.size}
        setValueAs="string"
        allowLeadingZeros
        mask={' '}
        onValueChange={async () => {
          context.clearErrors(['accountNumber']);
          await context.trigger('accountNumber');
        }}
      />
    </div>
  );
};
