import {cn} from '../lib/utils';
import {AnchorHTMLAttributes, FC} from 'react';

export const Link: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <a
      className={cn(
        'text-primary hover:underline hover:text-primary/80 font-medium',
        className
      )}
      {...props}
    >
      {children}
    </a>
  );
};
