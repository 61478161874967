import React, {useState} from 'react';
import {Formik} from 'formik';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {PlanRules} from './components/PlanRules';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {
  GetAccountQuery,
  InstalmentFrequency,
  InstalmentPlanMode,
  PaymentMethod,
  PaymentMethodStatus,
  useCreateInstalmentPlanMutation,
} from 'lib/graphql/API';
import {Select} from 'components/atoms/Select';
import {getImageAndAltText} from 'lib/logos';

import {BlockedSidebar} from '../components/BlockedSidebar';
import {useNavigate} from 'react-router-dom';
import {Instalments} from './components/Instalments';
import {GenerateInstalments} from './components/GenerateInstalments';
import {
  AbsoluteDate,
  DateFrequency,
  WEB_CONTEXT,
  err,
  errs,
} from 'payble-shared';
import {
  InstalmentPreview,
  generatePreviewInstalments,
} from '../helpers/generatePreviewInstlaments';
import {ValidationError} from 'payble-shared/src/errs';
import {useCurrentUser} from '../../../lib/auth';
import {SearchAccount} from 'components/organisms/SearchAccount';
import {formatPaymentMethod} from './utils/formatPaymentMethod';
import {useAPIInvalidate} from 'lib/api';

export type AddInstalmentPlanFormValues = {
  paymentMethodId?: string;
  accountId?: string;
  startDate?: AbsoluteDate;
  endDate?: AbsoluteDate;
  frequency?: DateFrequency;
  instalmentAmount?: number;
  instalments: InstalmentPreview[];
  mode?: string;
  planTotalAmount?: number;
  missed: number;
  canSkip: boolean;
};

const today = AbsoluteDate.today(WEB_CONTEXT);

const FORM_INITIAL_VALUES: AddInstalmentPlanFormValues = {
  paymentMethodId: undefined,
  accountId: undefined,
  instalments: [],
  startDate: today,
  endDate: today,
  frequency: undefined,
  instalmentAmount: undefined,
  mode: undefined,
  planTotalAmount: undefined,
  missed: 2,
  canSkip: true,
};

type AddInstalmentPlanFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  paymentMethods: PaymentMethod[] | null | undefined;
  defaultValues?: {
    instalmentAmount?: number;
    startDate?: AbsoluteDate;
    paymentMethodId?: string;
    planTotalAmount?: number;
    mode?: string;
    planFrequency?: InstalmentFrequency;
  };
} & (
  | {
      contactId: string;
      archivedContact: boolean;
      payerId?: undefined;
      accountId?: undefined;
      amountOwing?: undefined;
    }
  | {
      contactId?: undefined;
      archivedContact?: boolean;
      payerId: string;
      accountId?: string;
      amountOwing?: number;
    }
  | {
      contactId: string;
      archivedContact: boolean;
      payerId?: undefined;
      accountId: string;
      amountOwing: number;
    }
);

export const AddInstalmentPlanForm: React.FC<AddInstalmentPlanFormProps> = ({
  disclosure: {isOpen, onClose},
  paymentMethods: unfilteredPaymentMethods,
  contactId,
  archivedContact,
  payerId,
  accountId,
  amountOwing,
  defaultValues = {},
}) => {
  const navigate = useNavigate();

  const [accountData, setAccountData] = useState<GetAccountQuery>();
  const [createInstalmentPlan] = useCreateInstalmentPlanMutation();
  const invalidate = useAPIInvalidate();

  const {billerConfig} = useCurrentUser();
  const paymentMethods = unfilteredPaymentMethods?.filter(
    paymentMethod => paymentMethod.status === PaymentMethodStatus.Active
  );

  const noPaymentMethods = paymentMethods?.length === 0;

  if (archivedContact) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Plan"
        headerDescription="Add a new payment plan for contact."
        headline="Inactive contact"
        text="This contact is archived. Please unarchive the contact before continue."
        icon="ExclamationTriangleIcon"
      />
    );
  }

  if (noPaymentMethods) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Plan"
        headerDescription="Add a new payment plan for contact."
        headline="Missing Payment Method"
        text="To be able to create a payment plan the contact need to have a payment method. Please add a payment method to the contact."
        icon="ExclamationTriangleIcon"
      />
    );
  }

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={{...FORM_INITIAL_VALUES, ...defaultValues}}
        validate={values => {
          const errors: {
            paymentMethodId?: string;
            accountId?: string;
            instalments?: {[key: number]: string};
          } = {};

          if (!values.paymentMethodId) {
            errors.paymentMethodId = 'Payment method is required.';
          }

          if (!accountId && !values.accountId) {
            errors.accountId = 'Account is required.';
          }

          const instalmentsErrors: {[key: number]: string} = {};

          values.instalments.forEach((instalment, index) => {
            const errors: string[] = [];

            if (!instalment.dueAt) {
              errors.push('Due date is required and must be a valid date.');
            }

            if (
              values.instalments.filter(({dueAt}) =>
                instalment.dueAt.isEqual(dueAt)
              ).length > 1
            ) {
              errors.push('Due date must be unique.');
            }

            if (instalment.dueAt.isBefore(today)) {
              errors.push(
                `Start date cannot be before ${today.toFormat('dd/MM/yyyy')}`
              );
            }

            if (instalment.amount === undefined || instalment.amount <= 0) {
              errors.push('Amount is required and must be greater than 0.');
            }

            if (errors.length) {
              instalmentsErrors[index] = errors.join(' ');
            }
          });

          if (Object.keys(instalmentsErrors).length > 0) {
            errors.instalments = instalmentsErrors;
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          setSubmitting(true);
          const linkToAccountId = accountId ?? values.accountId;

          if (
            !values.paymentMethodId ||
            !linkToAccountId ||
            values.instalments.length === 0
          ) {
            return;
          }

          const result = await createInstalmentPlan({
            variables: {
              input: {
                accountId: linkToAccountId,
                contactId: contactId,
                payerId: payerId,
                paymentMethodId: values.paymentMethodId,
                payMode: InstalmentPlanMode.PayEveryX,
                instalments: values.instalments.map(({amount, dueAt}) => ({
                  amount,
                  dueAt,
                })),
                canSkip: values.canSkip,
                allowedMissedInstalments: values.missed,
              },
            },
            refetchQueries: ['getInstalmentPlanEvents'],
            awaitRefetchQueries: true,
          });

          if (result?.errors) {
            setSubmitting(false);

            const error = errs
              .fromGraphQL({
                graphQLErrors: result.errors,
              })
              .first();

            if (error instanceof ValidationError) {
              setErrors({
                accountId: error.message ?? 'Something went wrong.',
              });
            }

            return;
          }

          await invalidate({queryKey: ['instalment-plans']});

          setSubmitting(false);

          if (contactId && result.data?.createInstalmentPlan?.id) {
            onClose();
            navigate(`/audience/contact/${contactId}/instalment-plans`);
          }

          if (payerId && result.data?.createInstalmentPlan?.id) {
            onClose();
            navigate(`/audience/account/${linkToAccountId}/instalment-plans`);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Payment Plan"
                description="Add a new payment plan for contact."
              />
              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                {paymentMethods?.length && !noPaymentMethods ? (
                  <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="payment-method"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                      >
                        Payment Method
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <Select
                        onChange={async value => {
                          await setFieldValue('paymentMethodId', value);
                        }}
                        defaultValue={defaultValues?.paymentMethodId}
                        values={paymentMethods?.map(paymentMethod => ({
                          id: paymentMethod.id,
                          name: formatPaymentMethod(paymentMethod),
                          avatar: getImageAndAltText(paymentMethod).image,
                        }))}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {!accountId && (
                <SearchAccount
                  onDataChanged={data => {
                    setAccountData(data);
                    setFieldValue('accountId', data?.account?.id);
                  }}
                  onSearchError={error => {
                    setFieldError('accountId', error?.message);
                  }}
                  errorMessage={
                    errors.accountId && touched.accountId
                      ? errors.accountId
                      : undefined
                  }
                />
              )}
              <Instalments
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                errors={errors}
                localStartOfDay={today}
                defaultValues={{
                  InstalmentAmount: defaultValues.instalmentAmount,
                  mode: defaultValues.mode,
                  planTotalAmount:
                    defaultValues.planTotalAmount ||
                    accountData?.account?.amountOwing,
                  planFrequency: defaultValues.planFrequency,
                  startDate: defaultValues.startDate,
                }}
              />

              {(accountId || values.accountId) && (
                <PlanRules
                  missed={values.missed}
                  setFieldValue={setFieldValue}
                  canSkip={values.canSkip}
                />
              )}
            </div>

            {values.instalments.length > 0 ? (
              <FormSubmission
                isSubmitting={isSubmitting || noPaymentMethods}
                submissionDisabled={
                  values.instalments.length === 0 ||
                  Object.keys(errors).length > 0
                }
                onCancel={() => setFieldValue('instalments', [])}
                cancelButtonText="Reset"
                submissionButtonText="Add Payment Plan"
              />
            ) : (
              <GenerateInstalments
                onClick={() => {
                  if (!values.instalmentAmount) {
                    setFieldError('instalmentAmount', 'Amount is required.');
                    return;
                  }
                  if (
                    !values.startDate ||
                    !values.endDate ||
                    !values.frequency ||
                    !values.instalmentAmount ||
                    !values.mode
                  ) {
                    return;
                  }

                  if (values.startDate.isBefore(today)) {
                    setFieldError(
                      'startDate',
                      `Start date cannot be before ${today.toFormat('dd/MM/yyyy')}`
                    );
                    return;
                  }

                  const amountOwingForPlan =
                    amountOwing || accountData?.account?.amountOwing;

                  if (amountOwingForPlan === undefined) {
                    setFieldError('accountId', 'Account is required.');
                    return;
                  }

                  try {
                    const previewInstalments = generatePreviewInstalments(
                      {
                        startDate: values.startDate,
                        endDate: values.endDate,
                        frequency: values.frequency,
                        instalmentAmount: values.instalmentAmount,
                        mode: values.mode,
                        amountOwing:
                          values.planTotalAmount ?? amountOwingForPlan,
                      },
                      {billerConfig}
                    );

                    if (err(previewInstalments)) {
                      setFieldError('startDate', previewInstalments.message);
                      return;
                    }
                    setFieldValue('instalments', []);
                    setFieldValue('instalments', previewInstalments);
                  } catch (e) {
                    setFieldError('startDate', (e as Error).message);
                    return;
                  }
                }}
                onCancel={() => {
                  onClose();
                }}
                submissionDisabled={Object.keys(errors).length > 0}
              />
            )}
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
