import React from 'react';

import {Contact, useGetAccountQuery} from 'lib/graphql/API';
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  capitalize,
  err,
  formatToDollars,
  getBillerConfig,
  toTitleCase,
} from 'payble-shared';
import {Loading} from 'components/atoms/Loading';
import {Breadcrumbs} from 'components/atoms/Breadcrumbs';
import NumberFormat from 'react-number-format';
import {Tabs} from 'components/atoms/Tabs';
import {InstalmentPlans} from './InstalmentPlans';
import {Payments} from './Payments';
import {AccountMeta} from './AccountMeta';
import {auth} from '../../../lib';
import {AccountContacts} from './AccountContacts';
import {PathwayPreviewModal} from '../components/PathwayPreviewModal';
import {useDisclosure} from '../../../lib/hooks/useDisclosure';
import {useExecuteLocationQueryAction} from '../../../lib/hooks/useExecuteLocationQueryAction';

function hex2a(hexx: string) {
  const hex = hexx.toString(); //force conversion
  let str = '';
  for (let i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

const getVariables = (lookup: string, billerSlug: string) => {
  if (lookup.startsWith('mobile:')) {
    // Do a query based on the mobile
    return {
      mobile: lookup.replace('mobile:', ''),
    };
  }

  if (lookup.startsWith('email:')) {
    // Do a query based on the email
    return {
      email: lookup.replace('email:', ''),
    };
  }

  if (lookup.startsWith('hex:')) {
    // Do a query based on the externalId

    const hex = lookup.replace('hex:', '');
    const ascii = hex2a(hex);

    let externalId = '';
    let type = '';

    ascii.split('&').forEach(part => {
      const [key, value] = part.split('=');
      if (key === 'externalId') {
        externalId = value;
      } else if (key === 'type') {
        type = value;
      }
    });

    const config = getBillerConfig(billerSlug);
    return {
      externalId: config.normalizeInboundExternalId(externalId),
      type,
    };
  }
  return {
    id: lookup,
  };
};

export const Account: React.FC = () => {
  // 1 long page
  // Show connected contacts
  // Show meta data
  // Show instalment plans (active and inactive)
  // - automatically show newest instalment plan timeline on the right
  // Show one-off payments (if not associated with a plan)

  // actions:
  // - can remove contact from account
  // - can cancel instalment plan
  // - can trigger a charge for an instalment
  // - can trigger a charge for the remainder
  // - change payment method
  const {billerSlug: slug, billerConfig} = auth.useCurrentUser();
  const pathwayPreview = useDisclosure();
  /**
   * Open Pathway in-context view modal with queryString ?pathway=true
   */
  useExecuteLocationQueryAction('pathway', {true: pathwayPreview.onOpen});

  const navigate = useNavigate();
  const location = useLocation();
  const {lookup} = useParams<{lookup: string}>();
  if (!lookup) return <ErrorMessage message="No lookup provided" />;

  const variables = getVariables(lookup, slug);
  if (err(variables)) return <ErrorMessage message={variables.message} />;

  const {data, loading, error} = useGetAccountQuery({
    variables,
  });

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <ErrorMessage message={error.message} />
        </div>
      </div>
    );
  }

  if (!data || !data.account) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <ErrorMessage
            message={`Could not find an account with from lookup ${lookup}`}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="mt-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Breadcrumbs
            pages={[
              {name: 'Audience', href: '/audience'},
              {
                name: `${capitalize(billerConfig.getAccountTypeTitle(data.account.type))} - #${
                  data.account.externalId
                }`,
                href: `/audience/account/${data.account.id}`,
                current: true,
              },
            ]}
          />

          <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
            <div className="px-4 py-5 sm:px-6 relative">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Account Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details and account information.
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Account Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {capitalize(
                      billerConfig.getAccountTypeTitle(data.account.type)
                    )}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    External ID
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {data.account.externalId}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Amount Owing
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <NumberFormat
                      value={formatToDollars(data.account.amountOwing)}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalSeparator={'.'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                    />
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {data.account.description}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {toTitleCase(data.account.status)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Tabs
            tabs={[
              {
                name: 'QR Code & Metadata',
                onClick: () => {
                  navigate(`/audience/account/${lookup}`, {
                    state: {lookup},
                  });
                },
                current:
                  matchPath('/audience/account/:lookup', location.pathname) !==
                  null,
              },
              {
                name: 'Payment Plans',
                onClick: () => {
                  navigate(`/audience/account/${lookup}/instalment-plans`, {
                    state: {lookup},
                  });
                },
                current:
                  matchPath(
                    '/audience/account/:lookup/instalment-plans',
                    location.pathname
                  ) !== null,
              },
              {
                name: 'Payments',
                onClick: () => {
                  navigate(`/audience/account/${lookup}/payments`, {
                    state: {lookup},
                  });
                },
                current:
                  matchPath(
                    '/audience/account/:lookup/payments',
                    location.pathname
                  ) !== null,
              },
              {
                name: 'Contacts',
                onClick: () => {
                  navigate(`/audience/account/${lookup}/contacts`, {
                    state: {lookup},
                  });
                },
                current:
                  matchPath(
                    '/audience/account/:lookup/contacts',
                    location.pathname
                  ) !== null,
              },
            ]}
          />
        </div>
      </div>

      <div className="mt-8 pb-8">
        <Routes>
          <Route
            path=""
            element={<AccountMeta slug={slug} account={data.account} />}
          />
          <Route
            path="contacts"
            element={
              <AccountContacts contacts={data.account.contacts as Contact[]} />
            }
          />
          <Route
            path="instalment-plans"
            element={<InstalmentPlans accountId={data.account.id} />}
          />
          <Route
            path="payments"
            element={<Payments accountId={data.account.id} />}
          />
        </Routes>
      </div>
      <PathwayPreviewModal
        disclosure={{
          ...pathwayPreview,
          onClose: () => {
            pathwayPreview.onClose();
            navigate(location.pathname, {replace: true});
          },
        }}
      />
    </div>
  );
};
