import {z} from 'zod';
import {FormConfig, FormConfigField} from '../../types';
import {capitalize} from '../../../core/formatters';
import {PAYMENT_OPTIONS} from '../../../biller-config/handlers';

const accountType = z.enum(['rates', 'water', 'electricity', 'infringements']);

const itemSchema = z.object({
  paymentOptionId: z.string().uuid(),
  value: z.enum([...PAYMENT_OPTIONS]),
  label: z.string(),
  description: z.string(),
  accountType,
});

const paymentOptionSchema = z.object(
  accountType.options.reduce(
    (acc, key) => ({...acc, [key]: z.array(itemSchema).optional()}),
    {} as Record<
      z.infer<typeof accountType>,
      z.ZodOptional<z.ZodArray<typeof itemSchema>>
    >
  )
);

export type PaymentOptionSchema = z.infer<typeof paymentOptionSchema>;

const paymentOptionForm = accountType.options.map(type => {
  const capitalizeType = capitalize(type);
  return {
    type: 'list',
    name: type,
    label: `${capitalizeType} payment options`,
    swapable: true,
    fields: [
      {
        type: 'uuid',
        name: 'paymentOptionId',
        label: 'Payment option ID',
        hidden: true,
      },
      {
        type: 'dropdown',
        name: 'accountType',
        label: 'Account type',
        options: [type],
      },
      {
        type: 'dropdown',
        name: 'value',
        label: 'Payment type',
        options: [...PAYMENT_OPTIONS],
      },
      {type: 'text', name: 'label', label: 'Payment label'},
      {type: 'textarea', name: 'description', label: 'Payment description'},
    ],
    addItemLabel: `Add ${capitalizeType} Payment Option`,
  } satisfies FormConfigField;
});

export const payment_options = {
  title: 'Payment Options',
  configType: 'biller',
  validation: paymentOptionSchema,
  form: paymentOptionForm,
} satisfies FormConfig;
