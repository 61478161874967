import {
  FieldValues,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormInput} from './FormInput';
import {FormFieldError} from './FormFieldError';
import {FormInputPhone} from './FormInputPhone';
import {HTMLAttributes, useEffect, useMemo, useRef, useState} from 'react';
import {FormInputOTP} from './FormInputOTP';
import {z} from 'zod';
import {FormSubmitButton} from './FormSubmitButton';
import {cva} from '../lib/utils';
import {FormConnect} from './FormConnect';
import {FormErrorMessage} from './FormErrorMessage';
import {FormRadioGroup} from './FormRadioGroup';
import {FormCheckboxGroup} from './FormCheckboxGroup';
import {FormInputNumberFormat} from './FormInputNumberFormat';
import {FormSelect} from './FormSelect';
import {FormField} from './FormField';
import {FormSwitch} from './FormSwitch';
import {FormTextarea} from './FormTextarea';
import {FormFieldList} from './FormFieldList';
import {FormUuid} from './FormUuid';
import {FormInputDate} from './FormInputDate';
import {FormInputMoney} from './FormInputMoney';
import {errs} from 'payble-shared';
import {FormInputNumber} from './FormInputNumber';
import {FormAutocomplete} from './FormAutocomplete';
import {FormProvider, FormProviderValue} from '../hooks/useFormContext';
import {Label} from './Label';
import {FormInputTerms} from './FormInputTerms';
import {FormButton} from './FormButton';

const formVariants = cva('flex flex-col gap-5');

export type FormRef<T extends FieldValues> = UseFormReturn<T>;

export type FormProps<T extends FieldValues = FieldValues> = Omit<
  HTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  defaultValues?: UseFormProps<T>['defaultValues'];
  mode?: UseFormProps<T>['mode'];
  reValidateMode?: UseFormProps<T>['reValidateMode'];
  onSubmit?: (data: T, form: UseFormReturn<T>) => void;
  errors?: UseFormProps<T>['errors'];
  children: React.ReactNode;
  disabled?: boolean;
  formRef?: React.MutableRefObject<FormProviderValue | undefined>;
  schema?: z.ZodType<any, z.ZodTypeDef, any>;
  debug?: 'always' | boolean;
};

const useFormDebug = (debug?: FormProps['debug']) => {
  const lsDebug = useMemo<FormProps['debug'] | null>(() => {
    const stored = window.localStorage.getItem('debug-form');
    return stored === 'true'
      ? true
      : stored === 'false'
        ? false
        : stored === 'always'
          ? 'always'
          : null;
  }, []);
  const wantsDebug = debug ?? lsDebug;
  return wantsDebug;
};

export const Form = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  mode,
  reValidateMode,
  onSubmit,
  children,
  errors,
  schema,
  disabled,
  debug,
  ...props
}: FormProps<TFieldValues>) => {
  const methods = useForm({
    defaultValues,
    mode,
    reValidateMode,
    disabled,
    errors,
    shouldUnregister: true,
    resolver: schema ? zodResolver(schema) : undefined,
  });
  const [serverError, setServerError] = useState('');
  const wantsDebug = useFormDebug(debug);
  const [showDebugBox, setShowDebugBox] = useState<FormProps['debug']>(
    wantsDebug === 'always'
  );
  const formErrors = methods.formState.errors;

  useEffect(() => {
    const nonFieldErrors = Object.entries(methods.formState.errors)
      .filter(([key]) => !Object.keys(methods.getValues()).includes(key))
      .map(([key, e]) => `${key}: ${e?.message}`);

    if (nonFieldErrors.length) {
      throw new Error(
        `Found errors for fields that weren't found in the form:- ${nonFieldErrors.join(
          ','
        )}`
      );
    }
  }, [formErrors]);

  const submit: SubmitHandler<TFieldValues> = data => onSubmit?.(data, methods);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setServerError('');

    try {
      await methods.handleSubmit(submit)(e);
    } catch (e) {
      console.error(e);
      setServerError(errs.coerceToDomainError(e).message);
    }
  };

  const htmlFormRef = useRef<HTMLFormElement>(null);

  return (
    <FormProvider htmlFormRef={htmlFormRef} context={methods}>
      <form
        {...props}
        ref={htmlFormRef}
        className={formVariants({className: props.className})}
        onSubmit={async e => {
          e.stopPropagation();
          await handleSubmit(e);
        }}
      >
        {children}
      </form>

      {serverError && <FormErrorMessage message={serverError} />}

      {wantsDebug && (
        <div>
          <button
            onClick={() => setShowDebugBox(!showDebugBox)}
            className="text-xs"
          >
            debug
          </button>
          {showDebugBox && (
            <div className="text-xs">
              <FormConnect>
                {(values, form) => (
                  <div className="flex gap-4">
                    <div>
                      Form values:
                      <pre>{JSON.stringify(values, null, 2)}</pre>
                    </div>
                    <div>
                      Form state:
                      <pre>{JSON.stringify(form.formState, null, 2)}</pre>
                    </div>
                    <div>
                      Form isValid:
                      <pre>{form.formState.isValid}</pre>
                      <pre>{JSON.stringify(form.form.submit, null, 2)}</pre>
                    </div>
                  </div>
                )}
              </FormConnect>
            </div>
          )}
        </div>
      )}
    </FormProvider>
  );
};

Form.Field = FormField;
Form.Label = Label;
Form.Select = FormSelect;
Form.Switch = FormSwitch;
Form.Uuid = FormUuid;
Form.Input = FormInput;
Form.InputDate = FormInputDate;
Form.InputPhone = FormInputPhone;
Form.InputOTP = FormInputOTP;
Form.InputNumberFormat = FormInputNumberFormat;
Form.InputMoney = FormInputMoney;
Form.InputNumber = FormInputNumber;
Form.InputTerms = FormInputTerms;
Form.RadioGroup = FormRadioGroup;
Form.CheckboxGroup = FormCheckboxGroup;
Form.Textarea = FormTextarea;
Form.SubmitButton = FormSubmitButton;
Form.Button = FormButton;
Form.Connect = FormConnect;
Form.FieldError = FormFieldError;
Form.ErrorMessage = FormErrorMessage;
Form.List = FormFieldList;
Form.Autocomplete = FormAutocomplete;
