import {z} from 'zod';
import {AbsoluteDate} from './AbsoluteDate';

/** 1-indexed day of the month or the "end" of the month */
type Day = number | 'end';
/** 1-indexed months (1 -> Jan, 12 -> December) */
type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const DAY_MONTH_MAP = {
  1: 31,
  2: 28, // Ignoring leap years for this month. Use "end" to get the last day of the month.
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const PRETTY_MONTHS = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
} as const;

type PrettyMonth = keyof typeof PRETTY_MONTHS;

/** It's unlikely that using a leap-date will matter since we shouldn't be
 * defining these as 28-feb and end-feb */
const GENERIC_LEAP_YEAR = 2024;

export class DayOfMonth {
  constructor(
    private day: Day,
    private month: Month
  ) {
    this.validate();
  }

  static schema = z
    .string()
    .transform(val => DayOfMonth.maybeFromJSON(val))
    .pipe(z.instanceof(DayOfMonth))
    .or(z.instanceof(DayOfMonth));

  static maybeFromJSON(json: unknown) {
    if (json instanceof DayOfMonth) {
      return json;
    }

    if (typeof json !== 'string') {
      return null;
    }

    try {
      return DayOfMonth.fromJSON(json);
    } catch (e) {
      return null;
    }
  }

  static fromPretty(pretty: `${PrettyMonth}-${Day}`) {
    const [month, day] = pretty.split('-');
    const monthNum = PRETTY_MONTHS[month as PrettyMonth];
    if (!monthNum) {
      throw new Error(`Invalid month: ${month}`);
    }

    return new DayOfMonth(day === 'end' ? day : Number(day), monthNum);
  }

  /** 20-12 | end-12 */
  static fromJSON(json: unknown) {
    if (json instanceof DayOfMonth) {
      return json;
    }

    if (typeof json !== 'string') {
      throw new Error(`Invalid day of month ${JSON.stringify(json)}`);
    }

    const parts = json.split('-');

    if (parts.length !== 2) {
      throw new Error(`Invalid day of month ${json}`);
    }

    const month = Number(parts[0]);
    if (isNaN(month)) {
      throw new Error(`Invalid month: ${parts[0]}`);
    }

    if (parts[1] === 'end') {
      return new DayOfMonth('end', month as Month);
    }

    const day = Number(parts[1]);
    if (isNaN(day)) {
      throw new Error(`Invalid day: ${parts[1]}`);
    }

    return new DayOfMonth(day, month as Month);
  }

  static fromObject(obj: {day: Day; month: Month}) {
    return new DayOfMonth(obj.day, obj.month);
  }

  toJSON() {
    return `${this.month}-${this.day}`;
  }

  validate() {
    if (typeof this.month !== 'number') {
      throw new Error(`Invalid month: ${typeof this.month}: ${this.month}`);
    }
    const maxDay = DAY_MONTH_MAP[this.month];
    if (!maxDay) {
      throw new Error(`Invalid month: ${this.month}`);
    }

    if (this.day === 'end') {
      return;
    }

    if (typeof this.day !== 'number') {
      throw new Error(`Invalid day: ${typeof this.day}: ${this.day}`);
    }

    if (this.day < 1 || this.day > maxDay) {
      throw new Error(`Invalid day: ${this.day}`);
    }
  }

  isEqual(other: DayOfMonth) {
    const date = this.inYear(GENERIC_LEAP_YEAR);
    const otherDate = other.inYear(GENERIC_LEAP_YEAR);

    return date.isEqual(otherDate);
  }

  isAfter(other: DayOfMonth) {
    const date = this.inYear(GENERIC_LEAP_YEAR);
    const otherDate = other.inYear(GENERIC_LEAP_YEAR);

    return date.isAfter(otherDate);
  }

  isBefore(other: DayOfMonth) {
    return other.isAfter(this);
  }

  inYear(year: number) {
    const date = AbsoluteDate.fromObject({
      day: typeof this.day === 'number' ? this.day : 1,
      month: this.month,
      year,
    });

    if (this.day === 'end') {
      return date.endOf('month');
    }

    return date;
  }
}
