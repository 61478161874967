import {FC} from 'react';
import {Button, ButtonProps} from './Button';
import {useForm, useFormContext} from '../hooks/useFormContext';

export const FormButton: FC<ButtonProps> = props => {
  const {submit} = useForm();
  const {formState} = useFormContext();
  const {isSubmitting, isLoading, isValidating, disabled} = formState;

  return (
    <Button
      disabled={
        disabled ||
        submit.isDisabled ||
        isSubmitting ||
        isLoading ||
        isValidating
      }
      {...props}
    />
  );
};

FormButton.displayName = 'FormButton';
