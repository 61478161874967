import React, {useState} from 'react';
import {Formik} from 'formik';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {
  Account,
  GetAccountQuery,
  InstalmentPlanMode,
  PaymentMethod,
  PaymentMethodStatus,
  TargetInstalment,
  useCreateInstalmentPlanMutation,
} from 'lib/graphql/API';
import {Select} from 'components/atoms/Select';
import {getImageAndAltText} from 'lib/logos';

import {BlockedSidebar} from '../components/BlockedSidebar';
import {useNavigate} from 'react-router-dom';
import {InstalmentsOverview} from './components/InstalmentsOverview';
import {extractAccountDataWithKey} from './utils/extractAccountDataWithKey';
import {ValidationError} from 'payble-shared/src/errs';
import {errs} from 'payble-shared';
import {SearchAccount} from 'components/organisms/SearchAccount';
import {formatPaymentMethod} from './utils/formatPaymentMethod';
import {useAPIInvalidate} from 'lib/api';

export type AddInstalmentPlanFormValues = {
  paymentMethodId?: string;
  accountId?: string;
};

const FORM_INITIAL_VALUES: AddInstalmentPlanFormValues = {
  paymentMethodId: undefined,
  accountId: undefined,
};

type AddAutoPayFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  paymentMethods: PaymentMethod[] | null | undefined;
} & (
  | {
      contactId: string;
      archivedContact: boolean;
      payerId?: undefined;
      account?: undefined;
    }
  | {
      contactId?: undefined;
      archivedContact?: boolean;
      payerId: string;
      account: Account;
    }
);

export const AddAutoPayForm: React.FC<AddAutoPayFormProps> = ({
  disclosure: {isOpen, onClose},
  paymentMethods: unfilteredPaymentMethods,
  contactId,
  archivedContact,
  payerId,
  account,
}) => {
  const [accountData, setAccountData] = useState<GetAccountQuery>();

  const navigate = useNavigate();

  const [createInstalmentPlan] = useCreateInstalmentPlanMutation();

  const invalidate = useAPIInvalidate();

  const paymentMethods = unfilteredPaymentMethods?.filter(
    pm => pm.status === PaymentMethodStatus.Active
  );

  const noPaymentMethods = !paymentMethods || paymentMethods?.length === 0;

  if (archivedContact) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Plan"
        headerDescription="Add a new payment plan for contact."
        headline="Inactive contact"
        text="This contact is archived. Please unarchive the contact before continue."
        icon="ExclamationTriangleIcon"
      />
    );
  }

  if (noPaymentMethods) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Plan"
        headerDescription="Add a new payment plan for contact."
        headline="Missing Payment Method"
        text="To be able to create a payment plan the contact need to have a payment method. Please add a payment method to the contact."
        icon="ExclamationTriangleIcon"
      />
    );
  }

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validate={values => {
          const errors: {
            paymentMethodId?: string;
            accountId?: string;
            instalments?: {[key: number]: string};
          } = {};

          if (!values.paymentMethodId) {
            errors.paymentMethodId = 'Payment method is required.';
          }

          if (!account && !values.accountId) {
            errors.accountId = 'Account is required.';
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          setSubmitting(false);
          const linkToAccountId = account?.id ?? values.accountId;

          if (!values.paymentMethodId || !linkToAccountId) {
            return;
          }

          const result = await createInstalmentPlan({
            variables: {
              input: {
                accountId: linkToAccountId,
                contactId: contactId,
                payerId: payerId,
                paymentMethodId: values.paymentMethodId,
                payMode: InstalmentPlanMode.PayXOnY,
              },
            },
            refetchQueries: ['getInstalmentPlanEvents'],
            awaitRefetchQueries: true,
          });

          if (result?.errors) {
            setSubmitting(false);

            const error = errs
              .fromGraphQL({
                graphQLErrors: result.errors,
              })
              .first();

            if (error instanceof ValidationError) {
              setErrors({
                accountId: error.message ?? 'Something went wrong.',
              });
            }

            return;
          }

          await invalidate({queryKey: ['instalment-plans']});

          if (contactId && result.data?.createInstalmentPlan?.id) {
            onClose();
            navigate(`/audience/contact/${contactId}/instalment-plans`);
          }

          if (payerId && result.data?.createInstalmentPlan?.id) {
            onClose();
            navigate(`/audience/account/${linkToAccountId}/instalment-plans`);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => (
          <form
            className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Payment Plan"
                description="Add a new payment plan for contact."
              />
              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="payment-method"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Payment Method
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <Select
                      onChange={async value => {
                        await setFieldValue('paymentMethodId', value);
                      }}
                      values={paymentMethods?.map(paymentMethod => ({
                        id: paymentMethod.id,
                        name: formatPaymentMethod(paymentMethod),
                        avatar: getImageAndAltText(paymentMethod).image,
                      }))}
                    />
                  </div>
                </div>
              </div>
              {!account && (
                <SearchAccount
                  onDataChanged={data => {
                    setAccountData(data);
                    setFieldValue('accountId', data?.account?.id);
                  }}
                  onSearchError={error => {
                    setFieldError('accountId', error?.message);
                  }}
                  errorMessage={
                    errors.accountId && touched.accountId
                      ? errors.accountId
                      : undefined
                  }
                />
              )}
              <InstalmentsOverview
                heading="Outstanding amounts"
                label="type"
                instalments={
                  extractAccountDataWithKey({
                    account,
                    accountData,
                    key: 'setupBreakdown',
                  }) || []
                }
                showTotal
              />
              <InstalmentsOverview
                heading="Upcoming instalments"
                label="Date"
                showEmptyMessage={!!account || !!values.accountId}
                instalments={
                  extractAccountDataWithKey({
                    account,
                    accountData,
                    key: 'targetInstalments',
                  })?.map(({amount, dueAt}: TargetInstalment) => ({
                    amount,
                    label: dueAt ? dueAt.toFormat('dd MMM yyyy') : '',
                  })) ?? []
                }
              />
            </div>

            <FormSubmission
              isSubmitting={isSubmitting || noPaymentMethods}
              onCancel={() => onClose()}
              cancelButtonText="Cancel"
              submissionButtonText="Enable Auto Pay"
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
