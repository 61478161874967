import React from 'react';
import NumberFormat, {NumberFormatProps} from 'react-number-format';

type PhoneNumberFormatProps = NumberFormatProps & {
  region: string;
};

export const PhoneNumberFormat: React.FC<PhoneNumberFormatProps> = ({
  region,
  ...props
}) => {
  if (region === 'NZ') {
    return (
      <NumberFormat
        {...props}
        displayType={'text'}
        format="(+##) ### ##########"
      />
    );
  }

  if (region === 'AU') {
    return (
      <NumberFormat
        {...props}
        displayType={'text'}
        format="(+##) ### ### #######"
      />
    );
  }

  throw new Error(`Invalid phone number format for region: ${region}`);
};
