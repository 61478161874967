import {Form, Icon, Loading, Text, useFormContext} from 'payble-ui';
import {useDirectDebitRemoteValidationQuery} from '../hooks/useDirectDebitRemoteValidationQuery';
import {classNames} from '../lib/styles';
import {APIOutput} from 'payble-api-client';
import {FormInputNumberFormatProps} from 'payble-ui/src/components/FormInputNumberFormat';

const BsbNumberCheck = ({
  bank,
  isBankLoading,
}: {
  bank?: APIOutput<'biller', 'getBankByCode'> | null;
  isBankLoading?: boolean;
}) => {
  const context = useFormContext();

  if (context.formState.errors.bsbNumber?.type) return null;

  if (isBankLoading) {
    return <Loading variant="mini" centered={false} />;
  }

  if (bank) {
    return (
      <div className="flex items-center gap-1 text-left" id="valid-bsb">
        <Icon name="check" variant="fill" color="green" />
        <Text size="xs">{bank.name}</Text>
        {bank.icon && <img src={bank.icon} className="w-4 ml-1" />}
      </div>
    );
  }

  return null;
};

export const DirectDebitAUBsbNumberField = ({
  className,
  size,
  label,
  _label,
  useQueryHook,
}: {
  useQueryHook: Parameters<
    typeof useDirectDebitRemoteValidationQuery
  >[number]['useQueryHook'];
} & Pick<
  FormInputNumberFormatProps,
  'size' | 'className' | 'label' | '_label'
>) => {
  const context = useFormContext();
  const lookup = useDirectDebitRemoteValidationQuery({
    fieldName: 'bsbNumber',
    remoteValidatedFieldName: 'bsbNumberRemoteValidated',
    region: 'au',
    useQueryHook,
  });

  return (
    <div className="flex flex-col gap-3 flex-1">
      <Form.InputNumberFormat
        format="###-###"
        placeholder="000-000"
        name="bsbNumber"
        label={label}
        _label={_label}
        id="bsb"
        className={className ? classNames(className) : undefined}
        size={size}
        allowLeadingZeros
        onValueChange={async () => {
          context.setValue('bsbNumberRemoteValidated', false);
          context.clearErrors(['bsbNumber']);
          await context.trigger(['bsbNumber', 'bsbNumberRemoteValidated']);
        }}
      />
      <BsbNumberCheck isBankLoading={lookup.isFetching} bank={lookup.data} />
    </div>
  );
};
