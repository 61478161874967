import React from 'react';
import {z} from 'zod';
import {BlockedSidebar} from '../components/BlockedSidebar';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useAPIMutation} from 'lib/api';
import {Button, Form, useToast} from 'payble-ui';
import {zPhoneNumber} from 'payble-api-client/schemas';
import {PopOverCtaBottomRow} from 'components/atoms/PopOverCtaBottomRow';
import {AuthMethod} from 'lib/graphql/API';

type EditContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  givenName: string;
  familyName: string;
  email?: string;
  mobile?: string;
  authMethod: AuthMethod;
  contactId: string;
  archivedContact: boolean;
  refetch: () => void;
};

export const EditContactForm: React.FC<EditContactFormProps> = ({
  disclosure: {isOpen, onClose},
  givenName,
  familyName,
  email,
  mobile,
  authMethod,
  contactId,
  archivedContact,
  refetch,
}) => {
  const {toast} = useToast();
  const {mutateAsync: updateContact} = useAPIMutation('updateContact', {
    query: {
      onSuccess: () => {
        onClose();
        refetch();
      },
      onError: e => {
        toast({
          title: 'Failed to update contact',
          description: e.message,
          variant: 'destructive',
        });
      },
    },
  });
  if (archivedContact) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Edit contact"
        headerDescription="Edit contact name and email."
        headline="Inactive contact"
        text="This contact is archived. Please unarchive the contact before continue."
        icon="ExclamationTriangleIcon"
      />
    );
  }
  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Form
        className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
        schema={z.object({
          givenName: z.string(),
          familyName: z.string(),
          email: z.string().email().optional(),
          mobile: zPhoneNumber.optional(),
        })}
        defaultValues={{
          givenName,
          familyName,
          email,
          mobile,
        }}
        onSubmit={async data => {
          const {email, mobile, givenName, familyName} = data;
          await updateContact({
            givenName,
            familyName,
            email,
            mobile,
            contactId,
          });
        }}
      >
        <div className="flex-1">
          <FormHeader
            setOpen={onClose}
            title="Edit Contact"
            description="Edit contact name and email."
          />
          <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label
                  htmlFor="givenName"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  Given Name
                </label>
              </div>
              <div className="sm:col-span-2">
                <Form.Input
                  size="sm"
                  type="text"
                  name="givenName"
                  id="givenName"
                  placeholder="Given name"
                />
              </div>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label
                  htmlFor="familyName"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  Last Name
                </label>
              </div>
              <div className="sm:col-span-2">
                <Form.Input
                  size="sm"
                  type="text"
                  name="familyName"
                  id="familyName"
                  placeholder="Family name"
                />
              </div>
            </div>
            {authMethod === AuthMethod.Mobile && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                  >
                    E-mail
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <Form.Input
                    size="sm"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="you@example.com"
                  />
                </div>
              </div>
            )}
            {authMethod === 'email' && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="mobile"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                  >
                    Mobile
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <Form.InputPhone
                    size="sm"
                    name="mobile"
                    id="mobile"
                    placeholder="0400 000 000"
                    className="text-left"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <PopOverCtaBottomRow>
          <Button variant="outline" size="sm" full={false} onClick={onClose}>
            Cancel
          </Button>
          <Form.SubmitButton size="sm" full={false}>
            Update contact
          </Form.SubmitButton>
        </PopOverCtaBottomRow>
      </Form>
    </PopOverSidebar>
  );
};
