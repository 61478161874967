import {FC, InputHTMLAttributes, ReactNode} from 'react';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import {Controller} from 'react-hook-form';
import {InputProps, inputVariants} from './Input';
import {cn} from '../lib/utils';

export interface FormInputNumberFormatProps
  extends Omit<
    NumberFormatProps<InputHTMLAttributes<HTMLInputElement>>,
    'size' | 'type' | 'onValueChange'
  > {
  label?: ReactNode;
  _label?: Partial<React.ComponentProps<typeof FormField>>;
  name: string;
  format?: string;
  size?: InputProps['size'];
  allowNegative?: boolean;
  setValueAs?: 'formatted' | 'float' | 'string';
  onValueChange?: (value?: string | number) => void;
}

export const FormInputNumberFormat: FC<FormInputNumberFormatProps> = ({
  label,
  _label,
  name,
  disabled,
  format,
  size,
  allowNegative = false,
  setValueAs = 'formatted',
  onValueChange,
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField
      id={id}
      name={name}
      label={label}
      labelPosition={_label?.labelPosition}
      size={_label?.size}
    >
      <Controller
        control={form.control}
        name={name}
        disabled={disabled}
        render={({formState, field: {onChange, value, disabled}}) => {
          return (
            <NumberFormat
              id={id}
              data-testid="numberInput"
              {...props}
              allowNegative={allowNegative}
              value={value}
              format={format}
              disabled={disabled || formState.isSubmitting}
              placeholder={props.placeholder || format}
              mask={props.mask || '#'}
              onValueChange={async (values: NumberFormatValues) => {
                const value =
                  setValueAs === 'float'
                    ? values.floatValue
                    : setValueAs === 'formatted'
                      ? values.formattedValue
                      : setValueAs === 'string'
                        ? values.value?.toString()?.trim()
                        : values.value;

                onChange(value);
                onValueChange?.(value);
              }}
              onBlur={async () => {
                await form.trigger(name);
              }}
              spellCheck={false}
              autoComplete="off"
              className={cn(
                inputVariants({
                  size,
                  readOnly: !!props.readOnly,
                  className: props.className,
                })
              )}
            />
          );
        }}
      />
    </FormField>
  );
};
