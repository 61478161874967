import {ReactNode, useEffect, useState} from 'react';
import {Loading} from './Loading';
import {Popover, PopoverContent} from './Popover';
import {isDeepEmpty, getDotNotationValue, deepClearEmpty} from 'payble-shared';
import {Form} from './Form';
import {Button, ButtonProps} from './Button';
import {FormBuilder} from './FormBuilder';
import {FormConfigField} from 'payble-shared';
import {AnyZodObject} from 'zod';
import {useFormContext} from '../hooks/useFormContext';
import {PopoverTrigger} from '@radix-ui/react-popover';
import {Icon} from './Icon';

type Row = {[key in string]: unknown};

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

type Column<T> = {
  key: string;
  header: ReactNode;
  render?: (value: unknown, row: T) => ReactNode;
  visible?: boolean;
  filters?: {
    schema: AnyZodObject;
    form: FormConfigField[];
  };
};

type FilterData = {[key in string]: any};

type TableProps<T extends Row> = {
  loading?: boolean;
  error?: {message: string} | null;
  columns?: Column<T>[];
  rows?: T[];
  // rowKey is a key of T that must be string
  rowKey?: StringKeys<T> | (string & {__?: never});
  initialFiltersData?: FilterData;
  onFilterChanged?: (filters: FilterData) => void;
  children?: ReactNode;
  loadingComponent?: ReactNode;
  emptyComponent?: ReactNode;
};

function isColumnFilterActive(
  filtersData: FilterData,
  form?: FormConfigField[]
) {
  return (
    form?.some(field => {
      const value = getDotNotationValue(filtersData, field.name);
      return !isDeepEmpty(value);
    }) ?? false
  );
}

export const FilterIcon = ({
  filtersData,
  form,
  onClick,
}: {
  filtersData: FilterData;
  form?: FormConfigField[];
  onClick: () => void;
}) => {
  const isActive = isColumnFilterActive(filtersData, form);

  return (
    <span onClick={onClick}>
      <Icon
        name="filter"
        className={`cursor-pointer ${isActive ? 'text-primary' : ''}`}
        variant={isActive ? 'fill' : 'line'}
        size={16}
      />
    </span>
  );
};

export const ClearFiltersButton = ({
  columnFormDef,
  onCleared,
  ...props
}: ButtonProps & {
  columnFormDef: FormConfigField[];
  onCleared: (data: FilterData) => void;
}) => {
  const {resetField, getValues} = useFormContext();
  return (
    <Button
      {...props}
      size="sm"
      className="flex-1"
      variant="outline"
      onClick={e => {
        columnFormDef?.forEach(field => {
          resetField(field.name, {defaultValue: '', keepDirty: false});
        });
        onCleared(getValues());
        props.onClick?.(e);
      }}
    >
      Clear all
    </Button>
  );
};

const useFilterPopover = () => {
  const [filterPopoverOpened, setFilterPopoverOpened] = useState<{
    [k: string]: boolean;
  }>({});

  return {
    opened: filterPopoverOpened,
    toggle: (k: string) => {
      setFilterPopoverOpened({
        ...filterPopoverOpened,
        [k]: !filterPopoverOpened[k],
      });
    },
    close: (k: string) => {
      setFilterPopoverOpened({
        ...filterPopoverOpened,
        [k]: false,
      });
    },
  };
};

export const DataTable = <T extends Row>({
  loading,
  error,
  columns,
  rowKey = 'id',
  rows,
  initialFiltersData,
  onFilterChanged,
  children,
  loadingComponent,
  emptyComponent,
}: TableProps<T>) => {
  const [filtersData, setFiltersData] = useState(initialFiltersData ?? {});
  const filterPopover = useFilterPopover();

  useEffect(() => {
    onFilterChanged?.(filtersData);
  }, [filtersData]);

  function updateFiltersData(data: any) {
    setFiltersData(
      deepClearEmpty({
        ...filtersData,
        ...data,
      })
    );
  }

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {columns
              ?.filter(column => column.visible !== false)
              ?.map(column => (
                <th
                  key={column.key}
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:px-6"
                >
                  <div className="flex items-center gap-2">
                    {column.header}
                    {column.filters && (
                      <Popover open={filterPopover.opened[column.key]}>
                        <PopoverTrigger>
                          <FilterIcon
                            filtersData={filtersData}
                            form={column.filters.form}
                            onClick={() => {
                              filterPopover.toggle(column.key);
                            }}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <FormBuilder
                            id={`table-filter-${column.key}`}
                            defaultValues={filtersData}
                            schema={column.filters.schema}
                            fields={column.filters.form}
                            size="sm"
                            onSubmit={data => {
                              updateFiltersData(data);
                              filterPopover.close(column.key);
                            }}
                          >
                            <div className="flex items-center gap-2">
                              <ClearFiltersButton
                                onCleared={data => {
                                  updateFiltersData(data);
                                  filterPopover.close(column.key);
                                }}
                                columnFormDef={column.filters?.form}
                              />
                              <Form.SubmitButton size="sm">
                                Apply
                              </Form.SubmitButton>
                            </div>
                          </FormBuilder>
                        </PopoverContent>
                      </Popover>
                    )}
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {loading ? (
            loadingComponent ? (
              loadingComponent
            ) : (
              <tr>
                <td colSpan={columns?.length ?? 1} className="py-10">
                  <Loading />
                </td>
              </tr>
            )
          ) : null}
          {error ? (
            <tr>
              <td colSpan={columns?.length ?? 1} className="pb-10">
                {error.message}
              </td>
            </tr>
          ) : !loading && !rows?.length ? (
            <tr>
              <td colSpan={columns?.length ?? 1} className="py-10">
                {emptyComponent ?? 'No data'}
              </td>
            </tr>
          ) : null}
          {!loading &&
            rows?.map(row => (
              <tr key={`${row[rowKey]}`}>
                {columns
                  ?.filter(column => column.visible !== false)
                  ?.map(column => (
                    <td
                      key={`${row[rowKey]}-${column.key}`}
                      className="py-4 pl-3 text-sm text-gray-500 whitespace-nowrap sm:px-6"
                    >
                      {column.render?.(row[column.key], row) ??
                        (row[column.key] as ReactNode) ??
                        null}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
      {children}
    </div>
  );
};
