import {Form} from 'payble-ui';
import {UseTokenExProps, useTokenex} from '../hooks/useTokenex';
import {z} from 'zod';
import {TokenExCreditCardNumber} from './TokenExCreditCardNumber';
import {TokenExCreditCardCvv} from './TokenExCreditCardCvv';
import {DateTime} from 'luxon';
import {ReactNode} from 'react';

type ContactDetails = {
  givenName: string;
  familyName: string;
  email?: string;
  sendingEmailReceipts?: boolean;
};

interface TokenExCreditCardFormProps extends UseTokenExProps {
  id: string;
  contact?: ContactDetails | null;
  children?: React.ReactNode;
  Wrapper?: React.FC<{children: ReactNode; className?: string}>;
  showSendReceipts?: boolean;
  disabled?: boolean;
  onSubmit: (data: {
    card: {
      numberToken: string;
      numberTokenHmac: string;
      expiryMonth: number;
      expiryYear: number;
      brand: string;
      last4: string;
      holderName: string;
      referenceNumber: string;
    };
    contact: ContactDetails;
    usePaymentMethodForPlans?: string[];
    sendReceipts?: boolean;
  }) => Promise<void>;
}

const baseSchema = z
  .object({
    cardholder: z.string().min(1, {message: 'Required'}).max(255),
    cardNumber: z.string(),
    expiryDate: z.string().regex(/^\d{2}\/\d{2}$/),
    cvv: z.string(),
  })
  .required();

export const TokenExCreditCardForm = ({
  id,
  authKeyData,
  isLoadingAuthKey,
  reloadAuthKey,
  onSubmit,
  contact,
  children,
  showSendReceipts,
  disabled,
  Wrapper = ({children}) => children,
}: TokenExCreditCardFormProps) => {
  const {data, tokenEx, tokenizeAsync} = useTokenex({
    authKeyData,
    isLoadingAuthKey,
    reloadAuthKey,
  });
  const askForName = !contact?.givenName || !contact?.familyName;
  const askForEmail = !contact?.email;
  const schema = getSchema({askForEmail, askForName});

  return (
    <Form
      id={id}
      schema={schema}
      disabled={disabled}
      defaultValues={{
        givenName: contact?.givenName ?? '',
        familyName: contact?.familyName ?? '',
        cardholder:
          contact?.givenName && contact?.familyName
            ? `${contact.givenName} ${contact.familyName}`
            : '',
        email: contact?.email ?? '',
        expiryDate: '',
        sendReceipts: contact?.sendingEmailReceipts ?? false,
      }}
      onSubmit={async data => {
        if (!tokenEx) throw new Error('Tokenex is not ready');

        const expiry = DateTime.fromFormat(data.expiryDate, 'MM/yy');
        const cardData = await tokenizeAsync();
        const {givenName, familyName, email, cardholder} = data;
        const card = {
          holderName: cardholder,
          expiryMonth: parseInt(expiry.toFormat('MM')),
          expiryYear: parseInt(expiry.toFormat('yy')),
          last4: cardData.lastFour,
          numberToken: cardData.token,
          referenceNumber: cardData.referenceNumber,
          numberTokenHmac: cardData.tokenHMAC,
          brand: cardData.cardType,
        };

        const contact = {
          givenName,
          familyName,
          email,
        };

        await onSubmit({
          card,
          contact,
          // sendReceipts, TODO: ivan
        });
      }}
      className="w-full"
    >
      <Wrapper className="gap-4">
        {(!contact?.givenName || !contact?.familyName) && (
          <div className="grid grid-cols-2 gap-4">
            <Form.Input
              name="givenName"
              label="Given name"
              autoComplete="cc-given-name"
            />
            <Form.Input
              name="familyName"
              label="Family name"
              autoComplete="cc-family-name"
            />
          </div>
        )}
        {!contact?.email && (
          <Form.Input name="email" type="email" label="Email" />
        )}
        <Form.Input
          name="cardholder"
          autoComplete="cc-cardholder"
          type="text"
          label="Name on card"
        />
        <TokenExCreditCardNumber data={data} tokenEx={tokenEx} />
        <div className="grid grid-cols-2 gap-4">
          <Form.InputNumberFormat
            name="expiryDate"
            label="Expiry date"
            format="##/##"
            placeholder="MM/YY"
            mask={['M', 'M', 'Y', 'Y']}
            autoComplete="cc-exp"
          />
          <TokenExCreditCardCvv data={data} tokenEx={tokenEx} />
        </div>

        {showSendReceipts && (
          <Form.Input
            id="email-payment-receipts"
            name="sendReceipts"
            type="checkbox"
            label="Send payment receipts to my email"
            labelPosition="between"
          />
        )}
      </Wrapper>
      {children}
    </Form>
  );
};

function getSchema({
  askForEmail,
  askForName,
}: {
  askForEmail: boolean;
  askForName: boolean;
}) {
  let schema = baseSchema;

  if (askForName) {
    schema = schema.merge(
      z.object({
        givenName: z.string().min(1, {message: 'Required'}).max(255),
        familyName: z.string().min(1, {message: 'Required'}).max(255),
      })
    );
  }

  if (askForEmail) {
    schema = schema.merge(
      z.object({
        email: z.string().email(),
      })
    );
  }

  return schema;
}
