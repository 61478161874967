import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  FormProvider as RHFormProvider,
  useFormContext as useRHFormContext,
  UseFormReturn,
} from 'react-hook-form';

export const useFormContext = () => {
  const form = useRHFormContext();
  return form;
};

export type FormProviderValue = {
  ref: React.RefObject<HTMLFormElement>;
  context: UseFormReturn<any, any, any>;
  submit: {
    isDisabled: boolean;
    disable: () => void;
    enable: () => void;
    request: () => void;
  };
};

const Form = createContext<FormProviderValue | null>(null);

export const useForm = () => {
  const context = useContext(Form);
  if (!context) {
    throw new Error('Form context not provided');
  }
  return context;
};

export const useSubmit = (formRef?: React.RefObject<HTMLFormElement>) => {
  const [isDisabled, setDisabled] = useState(false);

  const disable = useCallback(() => setDisabled(true), []);
  const enable = useCallback(() => setDisabled(false), []);
  const request = useCallback(
    () =>
      formRef?.current?.dispatchEvent(
        new Event('submit', {cancelable: true, bubbles: true})
      ),
    [formRef]
  );

  return useMemo(
    () => ({isDisabled, disable, enable, request}),
    [isDisabled, request]
  );
};

export const FormProvider = ({
  htmlFormRef,
  context,
  children,
}: {
  htmlFormRef: FormProviderValue['ref'];
  context: FormProviderValue['context'];
  children: ReactNode;
}) => {
  const submit = useSubmit(htmlFormRef);

  return (
    <Form.Provider
      value={{
        ref: htmlFormRef,
        submit,
        context,
      }}
    >
      <RHFormProvider {...context}>{children}</RHFormProvider>
    </Form.Provider>
  );
};
