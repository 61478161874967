import {FC, useState} from 'react';
import React from 'react';
import {TermDefinition} from 'payble-shared/src/app-config/ConsumerAppConfig';
import DOMPurify from 'dompurify';
import snarkdown from 'snarkdown';
import {CheckboxGroup} from './CheckboxGroup';
import {DataTable} from './DataTable';
import {Dialog, DialogContent} from './Dialog';
import {Link} from './Link';

function markDownToHtml(
  markdown: string,
  allowedTags: string[] = ['a', 'u']
): string {
  // hook to make all links to open a new window
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });
  return DOMPurify.sanitize(snarkdown(markdown), {
    ALLOWED_TAGS: allowedTags,
  });
}

const TermsModal: React.FC<{
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  content:
    | {
        type: 'list';
        lines: string[];
      }
    | {
        type: 'table';
        lines: string[][];
      };
}> = ({isOpen, setOpen, content}) => {
  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent className="py-10">
        {content.type === 'list' && (
          <ul className="list-disc mt-2">
            {content.lines.map((term, index) => (
              <li
                key={index}
                className="text-sm text-gray-500 mt-1"
                dangerouslySetInnerHTML={{
                  __html: markDownToHtml(term),
                }}
              ></li>
            ))}
          </ul>
        )}
        {content.type === 'table' && (
          <DataTable
            rowKey="key"
            columns={content.lines[0].map(l => ({header: l, key: l}))}
            rows={content.lines.slice(1).map((l, key) => {
              const headerItems = content.lines[0];
              return l.reduce(
                (acc, item, i) => {
                  return {
                    ...acc,
                    [headerItems[i]]: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: markDownToHtml(item),
                        }}
                      />
                    ),
                  };
                },
                {key}
              );
            })}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export interface InputTermsProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  terms: TermDefinition[];
  disabled?: boolean;
}

export const TermBody = ({term}: {term: TermDefinition}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
      {term.definition.map(def => {
        if (def.type === 'inline') {
          return (
            <>
              <span
                key={def.itemId}
                dangerouslySetInnerHTML={{__html: markDownToHtml(def.content)}}
              />{' '}
            </>
          );
        }

        if (def.type === 'link') {
          return (
            <Link
              key={def.itemId}
              href={def.url}
              className="underline"
              onClick={e => {
                e.preventDefault();
                setModalOpen(true);
              }}
              target="_blank"
            >
              {def.content}
            </Link>
          );
        }

        if (def.type === 'modal') {
          return (
            <React.Fragment key={def.itemId}>
              <Link
                href="#"
                className="underline"
                onClick={e => {
                  e.preventDefault();
                  setModalOpen(true);
                }}
              >
                {def.contentTrigger}
              </Link>
              <TermsModal
                isOpen={modalOpen}
                setOpen={setModalOpen}
                content={def.content}
              />
            </React.Fragment>
          );
        }

        if (def.type === 'list') {
          return (
            <ul key={def.itemId}>
              {def.contentLines.map(item => (
                <li key={item}>{item}&nbsp;</li>
              ))}
            </ul>
          );
        }
      })}
    </>
  );
};

const InputTerms: FC<InputTermsProps> = ({
  value,
  onChange,
  terms,
  disabled,
}) => {
  const [checkedTerms, setCheckedTerms] = useState<string[]>(
    value === true ? terms.map(({termId}) => termId) : []
  );

  return (
    <CheckboxGroup
      value={checkedTerms}
      className="gap-4"
      labelPosition="right"
      options={terms.map(term => ({
        value: term.termId,
        label: <TermBody term={term} />,
      }))}
      onChange={values => {
        setCheckedTerms(values);

        const isAllChecked = terms.every(term => values.includes(term.termId));
        onChange?.(isAllChecked);
      }}
      disabled={disabled ?? false}
    />
  );
};

InputTerms.displayName = 'InputTerms';

export {InputTerms};
