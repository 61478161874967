import React from 'react';
import {Menubar} from 'payble-ui';
import {Action, useExecuteAction} from './useExecuteAction';
import {isPaybleStaff} from 'components/OnlyStaff';
import {useCurrentUser} from 'lib/auth';

type Navigation = {
  label: string[];
  action: Action;
  staffOnly?: boolean;
}[];

const NAVIGATION: Navigation = [
  {
    label: ['Audience'],
    action: {
      type: 'navigate',
      path: '/audience',
    },
  },
  {
    label: ['Payment', 'All'],
    action: {
      type: 'navigate',
      path: '/payments/all',
    },
  },
  {
    label: ['Payment', 'One-off payments'],
    action: {
      type: 'navigate',
      path: '/payments/full',
    },
  },
  {
    label: ['Payment', 'Payment plans'],
    action: {
      type: 'navigate',
      path: '/payments/plans',
    },
  },
  {
    label: ['Payment', 'Scheduled (30 days)'],
    action: {
      type: 'navigate',
      path: '/payments/scheduled',
    },
  },
  {
    label: ['Tools', 'Import'],
    action: {
      type: 'navigate',
      path: '/import-export',
      state: {mode: 'imports'},
    },
  },
  {
    label: ['Tools', 'Export'],
    action: {
      type: 'navigate',
      path: '/import-export',
      state: {mode: 'exports'},
    },
  },
  {
    label: ['Tools', 'Audit logs'],
    action: {
      type: 'navigate',
      path: '/audit',
    },
  },
  {
    label: ['Staff only', 'Environments config'],
    staffOnly: true,
    action: {
      type: 'navigate',
      path: '/config/environments/biller',
    },
  },
  {
    label: ['Staff only', 'Auto imports config', 'List'],
    staffOnly: true,
    action: {
      type: 'navigate',
      path: '/config/auto-imports',
    },
  },
  {
    label: ['Staff only', 'Auto imports config', 'Create'],
    staffOnly: true,
    action: {
      type: 'navigate',
      path: '/config/auto-imports/create',
    },
  },
];

export const NavMenubar: React.FC = () => {
  const execute = useExecuteAction();
  const currentUser = useCurrentUser();

  const dashboardItems: Navigation = currentUser.appConfig.dashboards.map(
    dashboard => ({
      label: ['Tools', 'Reports', dashboard.displayName],
      action: {
        type: 'navigate',
        path: `/reports/dashboard/${dashboard.reportingDashboardId}`,
      },
    })
  );

  return (
    <Menubar
      menu={[...NAVIGATION, ...dashboardItems]
        .filter(n => !n.staffOnly || isPaybleStaff(currentUser))
        .map(nav => ({
          ...nav,
          link: 'path' in nav.action ? nav.action.path : undefined,
          action: () => execute(nav.action),
        }))}
    />
  );
};
