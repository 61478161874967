import {
  Button,
  CommandShortcutDialog,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
} from 'payble-ui';
import {Action, useExecuteAction} from './useExecuteAction';
import {isPaybleStaff} from 'components/OnlyStaff';
import {useCurrentUser} from 'lib/auth';
import {removeCmdkCurrentForm, useCmdkState} from 'lib/cmdk/useCmdkState';
import {FormBuilderField} from 'payble-ui/src/components/FormBuilderField';
import {z} from 'zod';
import {BillerConfig, FormConfigField} from 'payble-shared';
import {
  isBillerCreatedAutoAvailableForBiller,
  isBillerCreatedFlexibleFixedPlanAvailableForBiller,
  isBillerCreatedPayEveryXPlanAvailableForBiller,
} from 'lib/featureAvailable';

type Navigation = {
  label: string;
  group: string;
  action: Action;
  staffOnly?: boolean;
  isAvailableForBiller?: (billerConfig: BillerConfig) => boolean;
};

const contactSearchForm = {
  form: {
    schema: z.object({
      contactId: z.string(),
    }),
    fields: [
      {
        type: 'remoteAutocomplete',
        endpoint: 'getAutocomplete',
        model: 'contact',
        label: 'Select a contact',
        placeholder: 'Search by name, email, or mobile number',
        name: 'contactId',
      },
    ],
    withoutButton: true,
  },
} satisfies {
  form: {
    schema: z.ZodObject<any, any, any>;
    fields: FormConfigField[];
    withoutButton?: boolean;
  };
};

const payerSearchForm = {
  form: {
    schema: z.object({
      payerId: z.string(),
    }),
    fields: [
      {
        type: 'remoteAutocomplete',
        endpoint: 'getAutocomplete',
        model: 'payer',
        label: 'Select a payer',
        placeholder: 'Search by external ID or description',
        name: 'payerId',
      },
    ],
    withoutButton: true,
  },
} satisfies {
  form: {
    schema: z.ZodObject<any, any, any>;
    fields: FormConfigField[];
    withoutButton?: boolean;
  };
};

const NAVIGATION = [
  {
    group: 'Audience',
    label: 'Search accounts',
    action: {
      type: 'navigate',
      path: '/audience',
      state: {mode: 'accounts'},
    },
  },
  {
    group: 'Audience',
    label: 'Search contacts',
    action: {
      type: 'navigate',
      path: '/audience',
      state: {mode: 'contacts'},
    },
  },
  {
    group: 'Audience',
    label: 'Create a contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience',
      state: {mode: 'contacts'},
      openDrawerId: 'create-contact',
    },
  },
  {
    group: 'Audience',
    label: 'Search payers',
    action: {
      type: 'navigate',
      path: '/audience',
      state: {mode: 'payers'},
    },
  },
  {
    group: 'Audience',
    label: 'Create a payer',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience',
      state: {mode: 'payers'},
      openDrawerId: 'create-payer',
    },
  },
  {
    group: 'Payment',
    label: 'Search all payments',
    action: {
      type: 'navigate',
      path: '/payments/all',
    },
  },
  {
    group: 'Payment',
    label: 'Search one-off payments',
    action: {
      type: 'navigate',
      path: '/payments/full',
    },
  },
  {
    group: 'Payment',
    label: 'Search payment plans',
    action: {
      type: 'navigate',
      path: '/payments/plans',
    },
  },
  {
    group: 'Payment',
    label: 'Search scheduled payments (30 days)',
    action: {
      type: 'navigate',
      path: '/payments/scheduled',
    },
  },
  {
    group: 'Contact',
    label: 'Send notification to contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'send-notification',
      args: {
        title: 'Send notification to contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Edit contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'edit-contact',
      args: {
        title: 'Edit a contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Archive contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'archive-contact',
      args: {
        title: 'Archive a contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Unarchive contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'unarchive-contact',
      args: {
        title: 'Unarchive a contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Enable autopay for a contact',
    isAvailableForBiller: isBillerCreatedAutoAvailableForBiller,
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'enable-autopay',
      args: {
        title: 'Enable autopay',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Enable flexible plan for a contact',
    isAvailableForBiller: isBillerCreatedPayEveryXPlanAvailableForBiller,
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'enable-flexible',
      args: {
        title: 'Enable flexible',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Manage notifications for a contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'manage-notification',
      args: {
        title: 'Manage notifications for a contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Add custom instalment plan for a contact',
    isAvailableForBiller: isBillerCreatedFlexibleFixedPlanAvailableForBiller,
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'add-instalment-plan',
      args: {
        title: 'Add instalment plan for a contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Contact',
    label: 'Add payment method for a contact',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/contact/:contactId',
      openDrawerId: 'add-payment-method',
      args: {
        title: 'Add payment method for a contact',
        ...contactSearchForm,
      },
    },
  },
  {
    group: 'Tools',
    label: 'Import',
    action: {
      type: 'navigate',
      path: '/import-export',
      state: {mode: 'imports'},
    },
  },
  {
    group: 'Tools',
    label: 'Export',
    action: {
      type: 'navigate',
      path: '/import-export',
      state: {mode: 'exports'},
    },
  },
  {
    group: 'Tools',
    label: 'Audit logs',
    action: {
      type: 'navigate',
      path: '/audit',
    },
  },
  {
    group: 'Staff only',
    label: 'Environments config',
    staffOnly: true,
    action: {
      type: 'navigate',
      path: '/config/environments/biller',
    },
  },
  {
    group: 'Staff only',
    label: 'Auto imports config',
    staffOnly: true,
    action: {
      type: 'navigate',
      path: '/config/auto-imports',
    },
  },
  {
    group: 'Payer',
    label: 'Enable autopay for a payer',
    isAvailableForBiller: isBillerCreatedAutoAvailableForBiller,
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/payer/:payerId',
      openDrawerId: 'enable-payer-autopay',
      args: {
        title: 'Enable autopay for a payer',
        ...payerSearchForm,
      },
    },
  },

  {
    group: 'Payer',
    label: 'Enable flexible plan for a payer',
    isAvailableForBiller: isBillerCreatedPayEveryXPlanAvailableForBiller,
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/payer/:payerId',
      openDrawerId: 'enable-payer-flexible',
      args: {
        title: 'Enable flexible for payer',
        ...payerSearchForm,
      },
    },
  },
  {
    group: 'Payer',
    label: 'Add custom instalment plan for a payer',
    isAvailableForBiller: isBillerCreatedFlexibleFixedPlanAvailableForBiller,
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/payer/:payerId',
      openDrawerId: 'add-payer-instalment-plan',
      args: {
        title: 'Add instalment plan for a payer',
        ...payerSearchForm,
      },
    },
  },
  {
    group: 'Payer',
    label: 'Archive payer',
    action: {
      type: 'navigate-open-drawer',
      path: '/audience/payer/:payerId',
      openDrawerId: 'archive-payer',
      args: {
        title: 'Find payer to archive',
        ...payerSearchForm,
      },
    },
  },
  {
    group: 'Payer',
    label: 'View payer payments',
    action: {
      type: 'navigate',
      path: '/audience/payer/:payerId/payments',

      args: {
        title: 'View payer payments',
        ...payerSearchForm,
      },
    },
  },

  {
    group: 'Payer',
    label: 'View payer payment methods',
    action: {
      type: 'navigate',
      path: '/audience/payer/:payerId/payment-method',

      args: {
        title: 'View payer payment methods',
        ...payerSearchForm,
      },
    },
  },
] satisfies Navigation[];

export const NavCommand: React.FC = () => {
  const execute = useExecuteAction();
  const currentUser = useCurrentUser();
  const cmdkState = useCmdkState();

  const dashboardItems: Navigation[] = currentUser.appConfig.dashboards.map(
    dashboard => ({
      group: 'Tools',
      label: `Dashboard for report "${dashboard.displayName}"`,
      action: {
        type: 'navigate',
        path: `/reports/dashboard/${dashboard.reportingDashboardId}`,
      },
    })
  );

  const groupedNavigation = [...NAVIGATION, ...dashboardItems]
    .filter(n => {
      if (n.isAvailableForBiller?.(currentUser.billerConfig) === false) {
        return false;
      }

      return !n.staffOnly || isPaybleStaff(currentUser);
    })
    .reduce(
      (acc, item) => {
        if (!acc[item.group]) {
          acc[item.group] = [];
        }
        acc[item.group].push(item);
        return acc;
      },
      {} as Record<string, Navigation[]>
    );

  return (
    <>
      <CommandShortcutDialog
        trigger={
          <Button variant="outline" size="sm" title="shortcut: ctrl + k">
            Search portal
          </Button>
        }
        commands={Object.entries(groupedNavigation).map(([group, items]) => ({
          type: 'group',
          label: group,
          children: items.map(item => ({
            type: 'item',
            label: item.label,
            onSelect: () => {
              execute(item.action);
            },
          })),
        }))}
      />
      <Dialog
        open={!!cmdkState.currentForm}
        onOpenChange={open => {
          if (!open) {
            removeCmdkCurrentForm();
          }
        }}
      >
        <DialogContent>
          <Form
            schema={cmdkState.currentForm?.schema}
            onSubmit={values => {
              cmdkState.currentForm?.onSubmit(values);
            }}
          >
            <DialogHeader>
              {cmdkState.currentForm?.title && (
                <DialogTitle>{cmdkState.currentForm?.title}</DialogTitle>
              )}
              <DialogDescription>
                {cmdkState?.currentForm?.fields?.map(field => (
                  <FormBuilderField
                    key={`cmdk-${field.name}`}
                    field={{size: 'sm', ...field}}
                  />
                ))}
              </DialogDescription>
              {!cmdkState?.currentForm?.withoutButton && (
                <DialogFooter className="mt-4">
                  <Button variant="outline">Cancel</Button>
                  <Form.SubmitButton>Next</Form.SubmitButton>
                </DialogFooter>
              )}
            </DialogHeader>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
