import {FC, ReactNode} from 'react';
import {InputAddon, InputAddonProps} from './InputAddon';
import {FormField, FormFieldProps} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';

export interface FormInputProps extends InputAddonProps {
  label?: ReactNode;
  labelPosition?: FormFieldProps['labelPosition'];
  name: string;
}

export const FormInput: FC<FormInputProps> = ({
  label,
  labelPosition,
  name,
  ...props
}) => {
  const {id, field, form} = useRegisterField(name);

  return (
    <FormField
      id={id}
      name={name}
      label={label}
      size={props.size}
      labelPosition={labelPosition}
    >
      <InputAddon
        id={id}
        {...props}
        {...field}
        disabled={
          props.disabled || field.disabled || form.formState.isSubmitting
        }
      />
    </FormField>
  );
};
