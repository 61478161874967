import {useParams} from 'react-router-dom';
import {
  EnvironmentFormConfigKey,
  findEnvironmentFormConfig,
  sortObjectByKey,
} from 'payble-shared';
import {Differ, Viewer} from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormBuilder,
  useToast,
} from 'payble-ui';
import {Spinner} from 'components/atoms/Spinner';
import {useGetConfigByType} from '../lib/useGetConfigByType';
import {
  useSaveConfigByType,
  useTestConfigByType,
} from '../lib/useSaveConfigByType';
import {useState} from 'react';

const differ = new Differ({
  maxDepth: 10,
  arrayDiffMethod: 'lcs',
});

export const EnvironmentConfigForm = () => {
  const {type} = useParams<{type: EnvironmentFormConfigKey}>();
  const {toast} = useToast();
  const [confirmingData, setConfirmingData] = useState();

  const {
    data: configData,
    isLoading: isFetchingConfig,
    error: fetchConfigError,
    refetch,
  } = useGetConfigByType(type);
  const {mutateAsync: saveConfig, isPending: isSavingConfig} =
    useSaveConfigByType(type);
  const {mutateAsync: testConfig, isPending: isTestingConfig} =
    useTestConfigByType(type);

  if (!type) {
    throw new Error(`Config type ${type} not found`);
  }

  const config = findEnvironmentFormConfig(type);
  const hasTest = config.validation.keyof().options.includes('test' as never);

  const oldValue = JSON.stringify(sortObjectByKey(configData ?? {}));
  const newValue = JSON.stringify(sortObjectByKey(confirmingData ?? {}));

  return (
    <div className="w-full p-8 bg-white rounded-lg">
      {fetchConfigError ? (
        <p className="text-red-500">{fetchConfigError.message}</p>
      ) : isFetchingConfig || !configData ? (
        <Spinner />
      ) : (
        <FormBuilder
          key={type}
          id="config-form"
          defaultValues={configData}
          fields={config.form}
          schema={config.validation}
          onSubmit={async data => {
            const {test, ...config} = data;
            if (test) {
              const result = await testConfig(config as never);
              if (result.success) {
                toast({
                  title: 'Success',
                  description: JSON.stringify(result.response, null, 2),
                });
              } else {
                toast({
                  title: 'Failed',
                  variant: 'destructive',
                  description: JSON.stringify(result.response, null, 2),
                });
              }
              return;
            }
            if (confirmingData) {
              setConfirmingData(undefined);
              await saveConfig(config as never);
              await refetch();
            } else {
              setConfirmingData(config as never);
            }
          }}
        >
          <div className="flex gap-4 pt-2">
            <Form.Connect>
              {(_, form) => (
                <>
                  {hasTest && (
                    <Form.SubmitButton
                      variant="outline"
                      isLoading={isTestingConfig}
                      onClick={() => {
                        form.setValue('test', true);
                      }}
                    >
                      Test
                    </Form.SubmitButton>
                  )}
                  <Form.SubmitButton
                    isLoading={isSavingConfig}
                    disabled={isTestingConfig}
                    onClick={() => {
                      form.setValue('test', false);
                    }}
                  >
                    Save
                  </Form.SubmitButton>
                </>
              )}
            </Form.Connect>
          </div>
        </FormBuilder>
      )}

      <Dialog
        open={!!confirmingData}
        onOpenChange={open => {
          if (!open) setConfirmingData(undefined);
        }}
      >
        <DialogContent className="max-w-full max-h-full">
          <DialogHeader>
            <DialogTitle>Are you absolutely sure?</DialogTitle>
            <DialogDescription>
              <div className="flex flex-col gap-4 max-h-[calc(100vh-250px)] overflow-y-scroll">
                <Viewer
                  diff={differ.diff(configData, confirmingData)}
                  lineNumbers
                  highlightInlineDiff
                  hideUnchangedLines
                />
                {oldValue === newValue && (
                  <p className="p-4 text-center">No Changes</p>
                )}
              </div>
            </DialogDescription>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setConfirmingData(undefined)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form="config-form"
                variant="accent"
                disabled={oldValue === newValue}
              >
                Confirm Save
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};
