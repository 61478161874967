import {DomainError} from 'payble-shared/src/errs';
import type * as billerAPISpec from '../biller';
import type * as consumerAPISpec from '../consumer';
import {
  AnyAPISpec,
  HTTPEndpointRequestData,
  HTTPEndpointResponse,
  HTTPQueryKey,
  QueryKeysOf,
} from '../utils';

export type APIQueryConfig<DEF extends AnyAPISpec> = {
  [OP in keyof DEF]: {
    /**
     * This is a default value, but can be overridden by the query hook.
     */
    staleTime?: number;
    /**
     * After a succesful API request this will invalidate these queries.
     */
    invalidates?: (
      request: HTTPEndpointRequestData<DEF[OP]>,
      response: HTTPEndpointResponse<DEF[OP]>
    ) => QueryKeysOf<DEF>[];
    onSuccess?: (args: {
      request: HTTPEndpointRequestData<DEF[OP]>;
      response: HTTPEndpointResponse<DEF[OP]>;
      updateCache: (
        queryKey: HTTPQueryKey<DEF[keyof DEF]>,
        updater: (oldData: unknown) => unknown
      ) => void;
    }) => void;
    /**
     * After a succesful API request this will invalidate these queries.
     *
     * This is really only needed because we foolishly use failed HTTP requests
     * to denote failed payments.
     */
    errorInvalidates?: (
      request: HTTPEndpointRequestData<DEF[OP]>,
      error: DomainError
    ) => QueryKeysOf<DEF>[];
  };
};

export const consumerQueryConfig: APIQueryConfig<typeof consumerAPISpec> = {
  /**
   * Session
   */
  getSessionContact: {
    staleTime: Infinity,
  },
  getConsumerAppConfig: {
    staleTime: Infinity,
  },

  /**
   * Checkout
   */
  startCheckout: {
    onSuccess({response, updateCache}) {
      updateCache(['checkout', response.ct], () => response.checkout);
    },
  },
  addCheckoutPaymentMethod: {
    onSuccess({request, response, updateCache}) {
      updateCache(['checkout', request.ct], () => response);
    },
    invalidates: () => [['user', 'contact']],
  },
  completeCheckout: {
    onSuccess({request, response, updateCache}) {
      updateCache(['checkout', request.ct], () => response);
    },
  },
  getCheckout: {
    staleTime: 1000 * 60 * 30,
  },

  /**
   * Analytics endpoints (These do not use react-query) and don't invalidate
   * anything.
   */
  analyticsIdentify: {},
  analyticsPublishEvent: {},

  /**
   * Payment endpoints
   */
  payAccountAmount: {
    invalidates: () => [['account'], ['user', 'payments']],
    errorInvalidates: () => [['account'], ['user', 'payments']],
  },
  payAccountAnonymously: {
    invalidates: () => [['account'], ['user', 'payments']],
    errorInvalidates: () => [['account'], ['user', 'payments']],
  },
  payInstalment: {
    invalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
      ['account'],
    ],
    errorInvalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
      ['account'],
    ],
  },
  getPayment: {
    staleTime: 60_000,
  },
  getPayments: {
    staleTime: 60_000,
  },
  payInstalmentPlanRemainder: {
    invalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
      ['account'],
    ],
    errorInvalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
      ['account'],
    ],
  },

  /**
   * Instalment plan endpoints
   */
  previewInstalmentPlan: {
    // No stale time, this should always be fresh
  },
  getInstalmentPlan: {
    staleTime: 60_000,
  },
  getInstalmentPlans: {
    staleTime: 60_000,
  },
  cancelInstalmentPlan: {
    invalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
    ],
  },
  createInstalmentPlan: {
    invalidates: () => [['user', 'instalment-plans']],
  },
  createPlanRequest: {
    // Does this not update anything?
    invalidates: () => [['account'], ['user', 'instalment-plans']],
  },
  skipInstalment: {
    invalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
    ],
  },
  updateInstalmentAmount: {
    invalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
    ],
  },

  /**
   * Payment method endpoints
   */
  changeInstalmentPlanPaymentMethod: {
    invalidates: ({instalmentPlanId}) => [
      ['user', 'instalment-plans'],
      ['instalment-plan', instalmentPlanId],
    ],
  },
  addPaymentMethod: {
    invalidates: () => [
      ['user', 'contact'],
      ['user', 'instalment-plans'],
      ['instalment-plan'],
    ],
  },
  removePaymentMethod: {
    invalidates: () => [['user', 'contact']],
  },

  /**
   * Checkout/tokenization endpoints
   */
  getAuthKey: {
    staleTime: 300_000,
  },
  getAuthKeyAnonymously: {
    staleTime: 300_000,
  },
  getBankByCode: {
    staleTime: Infinity,
  },

  /**
   * Account endpoints
   */
  addContactToAccount: {
    invalidates: () => [['account']],
  },
  getAccountByExternalId: {
    staleTime: 60_000,
  },
  getAccountByExternalIdAnonymously: {
    staleTime: 60_000,
  },
  accountInstalmentRange: {
    // Always fresh
  },

  /**
   * Notifications
   */
  getNotificationSettings: {
    staleTime: 60_000,
  },
  updateNotificationSettings: {
    invalidates: () => [['notification-settings']],
  },
  updateContact: {
    invalidates: () => [['notification-settings'], ['user', 'contact']],
  },

  /**
   * Authentication.
   * We assume the application wipes the query client after these are
   * completed.
   */
  loginComplete: {},
  loginStart: {},
  logout: {},
};

export const billerQueryConfig: APIQueryConfig<typeof billerAPISpec> = {
  archiveAutoImportConfig: {},
  archiveContact: {},
  contactRemove: {},
  createContact: {},
  getAllAutoImportConfigs: {},
  getAnalytics: {},
  getAutoImportConfig: {},
  getInstalmentPlans: {},
  getNotificationSettings: {},
  getSession: {},
  rebalancePlan: {},
  resetBiller: {},
  restoreContact: {},
  saveAutoImportConfig: {},
  searchAccounts: {},
  searchContacts: {},
  getAutocomplete: {},
  searchPayments: {},
  updateContact: {},
  updateNotificationSettings: {},
  lookupAccounts: {},
  getBankByCode: {},
  getPathwayImageURL: {},
  getReportingDashboard: {},
  removePaymentMethod: {},
};
