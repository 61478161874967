import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useExecuteLocationQueryAction = (
  queryKey: string,
  actionMap: Record<string, () => void>
) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const queryKeyValue = queryParams.get(queryKey);
    if (typeof queryKeyValue === 'string' && queryKeyValue in actionMap) {
      actionMap[queryKeyValue]();
    }
  }, [location.search]);

  return null;
};
