import {AbsoluteDate} from './AbsoluteDate';
import {DayOfMonth} from './DayOfMonth';
import {DateContext} from './time';

/**
 * This is substantially different to other types of date ranges.
 *
 */
export class DayOfMonthRange {
  constructor(
    public start: DayOfMonth,
    public end: DayOfMonth
  ) {
    this.validate();
  }

  static fromPretty(args: {
    start: Parameters<typeof DayOfMonth.fromPretty>[0];
    end: Parameters<typeof DayOfMonth.fromPretty>[0];
  }) {
    return new DayOfMonthRange(
      DayOfMonth.fromPretty(args.start),
      DayOfMonth.fromPretty(args.end)
    );
  }

  static fromJSON(json: unknown) {
    if (json instanceof DayOfMonthRange) {
      return json;
    }

    if (typeof json !== 'string') {
      throw new Error(`Invalid day of month window ${JSON.stringify(json)}`);
    }

    return DayOfMonthRange.fromISO(json);
  }

  static fromISO(iso: string) {
    const parts = iso.split('/');
    if (parts.length !== 2) {
      throw new Error(`Invalid day of month window ${iso}`);
    }

    const [start, end] = parts;

    return new DayOfMonthRange(
      DayOfMonth.fromJSON(start),
      DayOfMonth.fromJSON(end)
    );
  }

  toISO() {
    return `${this.start.toJSON()}/${this.end.toJSON()}`;
  }

  toJSON() {
    return this.toISO();
  }

  validate() {
    if (this.start.isEqual(this.end)) {
      throw new Error(
        `Invalid day of month window: ${this.start.toJSON()}/${this.end.toJSON()}`
      );
    }
  }

  findPeriod(date: AbsoluteDate):
    | {
        state: 'within_period';
        period: AbsoluteDateRange;
      }
    | {
        state: 'out_of_period';
        nextPeriod: AbsoluteDateRange;
        previousPeriod: AbsoluteDateRange;
      } {
    const thisYear = date.year;

    const periods = [
      this.endingInYear(thisYear - 1),
      this.endingInYear(thisYear),
      this.endingInYear(thisYear + 1),
    ];

    const withinPeriod = periods.find(period => period.containsInclusive(date));
    if (withinPeriod) {
      return {period: withinPeriod, state: 'within_period' as const};
    }

    const nextPeriodIdx = periods.findIndex(period =>
      period.start.isAfterOrEqual(date)
    );
    if (nextPeriodIdx === -1) {
      throw new Error(`Unable to find next period for date ${date.toJSON()}`);
    }

    const nextPeriod = periods[nextPeriodIdx];
    const previousPeriod = periods[nextPeriodIdx - 1];

    if (!nextPeriod || !previousPeriod) {
      throw new Error(
        `Unable to calculate period for day of month range ${JSON.stringify(this.toJSON())}`
      );
    }

    if (nextPeriod) {
      return {nextPeriod, previousPeriod, state: 'out_of_period' as const};
    }

    throw new Error(
      `Unable to calculate period for day of month range ${JSON.stringify(this.toJSON())}`
    );
  }

  endingInYear(year: number): AbsoluteDateRange {
    const start = this.start.inYear(year);
    const end = this.end.inYear(year);

    if (end.isBefore(start)) {
      return new AbsoluteDateRange(this.start.inYear(year - 1), end);
    }

    return new AbsoluteDateRange(start, end);
  }
}

export class AbsoluteDateRange {
  constructor(
    public start: AbsoluteDate,
    public end: AbsoluteDate
  ) {}

  static fromJSON(json: unknown) {
    if (json instanceof AbsoluteDateRange) {
      return json;
    }

    if (typeof json !== 'string') {
      throw new Error(`Invalid date range ${JSON.stringify(json)}`);
    }

    return AbsoluteDateRange.fromISO(json);
  }

  static fromISO(iso: string) {
    const parts = iso.split('/');
    if (parts.length !== 2) {
      throw new Error(`Invalid date range ${iso}`);
    }

    const [start, end] = parts;

    return new AbsoluteDateRange(
      AbsoluteDate.fromISO(start),
      AbsoluteDate.fromISO(end)
    );
  }

  containsInclusive(other: AbsoluteDate) {
    return this.start.isBeforeOrEqual(other) && this.end.isAfterOrEqual(other);
  }

  toDateRange(context: DateContext) {
    return new DateRange(
      this.start.toDateTime(context).startOf('day').toJSDate(),
      this.end.toDateTime(context).endOf('day').toJSDate()
    );
  }

  toISO() {
    return `${this.start.toISO()}/${this.end.toISO()}`;
  }

  toJSON() {
    return this.toISO();
  }
}

export class DateRange {
  constructor(
    public start: Date,
    public end: Date
  ) {}

  toISO() {
    return `${this.start.toISOString()}/${this.end.toISOString()}`;
  }
}
