import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import {cn, cva} from '../lib/utils';
import {
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  forwardRef,
  useId,
} from 'react';
import {IconResolver, IconResolverIcon} from './IconResolver';

export interface RadioGroupProps
  extends ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  size?: 'sm' | 'default';
  className?: string;
  direction?: 'horizontal' | 'vertical';
  variant?: 'default' | 'minimal';
  options?: {
    id?: string;
    value: string | number;
    label: ReactNode;
    iconL?: IconResolverIcon | IconResolverIcon[];
    iconR?: IconResolverIcon | IconResolverIcon[];
    description?: ReactNode;
  }[];
}

const rootVariants = cva('flex gap-3', {
  variants: {
    direction: {
      horizontal: '',
      vertical: 'flex-col',
    },
    disabled: {
      false: '',
      true: 'opacity-50',
    },
  },
  defaultVariants: {
    direction: 'vertical',
    disabled: false,
  },
});

const boxVariants = cva(
  'flex flex-1 gap-3 p-3 rounded-lg cursor-pointer justify-between',
  {
    variants: {
      variant: {
        default: 'border border-border shadow-xs',
        minimal: 'text-center',
      },
      selected: {
        true: 'bg-white border-primary ring-2 ring-primary',
        false: 'bg-white',
      },
    },
    defaultVariants: {
      variant: 'default',
      selected: false,
    },
    compoundVariants: [
      {
        variant: 'minimal',
        selected: true,
        class: 'bg-white shadow-xs',
      },
      {
        variant: 'minimal',
        selected: false,
        class: 'bg-gray-100',
      },
    ],
  }
);

const buttonVariants = cva(
  'flex items-center justify-center aspect-square h-5 w-5 rounded-full text-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        minimal: 'hidden',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const labelVariants = cva('text-md text-foreground', {
  variants: {
    variant: {
      default: 'font-semibold',
      minimal: '',
    },
    selected: {
      true: '',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    selected: false,
  },
  compoundVariants: [
    {
      variant: 'minimal',
      selected: true,
      class: 'font-semibold',
    },
    {
      variant: 'minimal',
      selected: false,
      class: 'font-regular',
    },
  ],
});

const RadioGroup: FC<RadioGroupProps> = forwardRef<
  HTMLInputElement,
  RadioGroupProps
>(({className, options, disabled, ...props}, ref) => {
  const id = useId();
  const value = props.value?.toString();
  const showDescription = props.variant !== 'minimal';
  const enrichedOptions = options?.map(option => ({
    ...option,
    value: option.value?.toString(),
    cvaProps: {
      ...props,
      selected: value === option.value?.toString(),
    },
  }));

  return (
    <RadioGroupPrimitive.Root
      {...props}
      value={value}
      ref={ref}
      className={cn(rootVariants({...props, disabled}), className)}
    >
      {enrichedOptions?.map(option => (
        <label
          htmlFor={`${option.id ?? `${option.value}-${id}`}`}
          className={cn(boxVariants(option.cvaProps))}
          key={`${option.value}-${id}`}
        >
          <MayBeArrayIcon icons={option?.iconL} />
          <div className="flex flex-col flex-1 gap-3">
            <span className={cn(labelVariants(option.cvaProps))}>
              {option.label}
            </span>
            {showDescription && option.description && (
              <p className="text-sm font-medium">{option.description}</p>
            )}
          </div>
          <MayBeArrayIcon icons={option?.iconR} />
          <RadioGroupPrimitive.Item
            id={`${option.id ?? `${option.value}-${id}`}`}
            value={option.value}
            disabled={disabled}
            className={cn(buttonVariants(option.cvaProps))}
          >
            <RadioGroupPrimitive.Indicator>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    id="Vector"
                    d="M12.5 2.08301C9.73726 2.08301 7.08801 3.18066 5.13476 5.13476C3.18066 7.08788 2.08301 9.73726 2.08301 12.5C2.08301 15.2628 3.18066 17.912 5.13476 19.8653C7.08788 21.8194 9.73726 22.917 12.5 22.917C15.2628 22.917 17.912 21.8194 19.8653 19.8653C21.8194 17.9121 22.917 15.2628 22.917 12.5C22.917 9.73726 21.8194 7.08801 19.8653 5.13476C17.9121 3.18066 15.2628 2.08301 12.5 2.08301ZM18.412 10.663L12.162 16.3925C11.7509 16.7695 11.1161 16.7558 10.7216 16.3613L7.59658 13.2363C7.39541 13.0419 7.28116 12.7753 7.27921 12.496C7.27628 12.2167 7.38663 11.9482 7.58391 11.7509C7.78117 11.5527 8.04973 11.4433 8.32903 11.4452C8.60931 11.4482 8.87591 11.5624 9.06926 11.7636L11.4892 14.1835L17.0039 9.12776C17.4278 8.73908 18.0869 8.76741 18.4756 9.19124C18.8653 9.61506 18.8368 10.2743 18.412 10.663Z"
                    fill="#2693FF"
                  />
                </g>
              </svg>
            </RadioGroupPrimitive.Indicator>
          </RadioGroupPrimitive.Item>
        </label>
      ))}
    </RadioGroupPrimitive.Root>
  );
});

function MayBeArrayIcon({
  icons,
}: {
  icons: NonNullable<RadioGroupProps['options']>[number]['iconL'];
}) {
  if (icons) {
    return Array.isArray(icons) ? (
      <div className="flex gap-1 items-center">
        {icons.map((icon, idx) => (
          <IconResolver key={idx} icon={icon} />
        ))}
      </div>
    ) : (
      <IconResolver icon={icons} />
    );
  }
  return null;
}

export {RadioGroup};
