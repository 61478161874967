import {FC, ReactNode} from 'react';
import {useRegisterFieldArray} from '../hooks/useRegisterFieldArray';
import {FormField} from './FormField';
import {FormBuilderField} from './FormBuilderField';
import {FormConfig, FormConfigField} from 'payble-shared';
import {IconButton} from './IconButton';
import {Button} from './Button';

type FormFieldListProps = {
  description?: ReactNode;
  label?: ReactNode;
  fields: FormConfigField[];
  addItemLabel?: string;
  context?: FormConfig['context'];
  name: string;
  swapable?: boolean;
};

export const FormFieldList: FC<FormFieldListProps> = ({
  name,
  label,
  addItemLabel = 'Add item',
  description,
  fields,
  context,
  swapable,
}) => {
  const {
    id,
    fields: formFields,
    append,
    remove,
    swap,
  } = useRegisterFieldArray(name);

  return (
    <FormField id={id} name={name} label={label} description={description}>
      <ul className="flex flex-col gap-3">
        {formFields.map((item, index) => (
          <li key={item.id} className="flex flex-col gap-3 p-4 shadow-md">
            {fields.map(field => (
              <FormBuilderField
                key={`${item.id}${field.name}`}
                field={{
                  ...field,
                  name: `${name}.${index}.${field.name}`,
                  label: field.label,
                }}
                context={{...context, index}}
                className={'hidden' in field && field.hidden ? 'hidden' : ''}
              />
            ))}
            <div className="flex items-center justify-between">
              {swapable && (
                <div>
                  <IconButton
                    onClick={() => swap(index, index - 1)}
                    icon="arrowUp"
                    variant="ghost"
                    className="shrink-0"
                    style={{marginTop: index === 0 ? '35px' : 0}}
                    disabled={index <= 0}
                  />
                  <IconButton
                    onClick={() => swap(index, index + 1)}
                    icon="arrowDown"
                    variant="ghost"
                    className="shrink-0"
                    style={{marginTop: index === 0 ? '35px' : 0}}
                    disabled={index >= formFields.length - 1}
                  />
                </div>
              )}
              <IconButton
                onClick={() => remove(index)}
                icon="trash"
                variant="ghost"
                className="shrink-0"
                style={{marginTop: index === 0 ? '35px' : 0}}
              />
            </div>
          </li>
        ))}
      </ul>
      <Button
        onClick={() => append({})}
        variant="outline"
        className="w-auto"
        size="sm"
      >
        {addItemLabel}
      </Button>
    </FormField>
  );
};
