import {cn} from '../lib/utils';
import {Icon, IconName, IconProps} from './Icon';

export type IconResolverIcon =
  | IconName
  | IconProps
  | {src: string; alt?: string; width?: number; height?: number};

export const IconResolver = ({
  icon,
  className,
}: {
  icon?: IconResolverIcon;
  className?: string;
}) => {
  if (!icon) return null;

  const mergedClassName = cn('shrink-0', className);

  if (typeof icon === 'string') {
    return <Icon name={icon} className={mergedClassName} />;
  }

  if (typeof icon === 'object') {
    if ('src' in icon) {
      return (
        <img {...icon} width={icon.width ?? 20} className={mergedClassName} />
      );
    }
    return <Icon {...icon} className={mergedClassName} />;
  }

  return null;
};
