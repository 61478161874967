import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';

export const getAutocomplete = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /getAutocomplete',
  queryKey: data => ['autocomplete', data?.model, data?.search],
  requestSchema: z.object({
    model: z.enum(['contact', 'payer']),
    search: z.string(),
  }),
  responseSchema: z.array(z.object({label: z.string(), value: z.string()})),
});
