import {Icon, MiniSpinner, useFormContext} from 'payble-ui';
import {useDirectDebitRemoteValidationQuery} from 'payble-app-shared/src/hooks/useDirectDebitRemoteValidationQuery';
import {DirectDebitNZAccountNumberInput} from './DirectDebitNZAccountNumberInput';
import {FormInputNumberFormatProps} from 'payble-ui/src/components/FormInputNumberFormat';

const AccountNumberCheck = ({
  isFetching,
  data,
}: {
  isFetching: boolean;
  data?: {name: string} | null;
}) => {
  const {formState} = useFormContext();

  if (isFetching) {
    return <MiniSpinner />;
  }

  if (formState.errors?.accountNumber) {
    return (
      <div className="flex items-center gap-1">
        <Icon name="close" size={16} className="text-red-500" />
        <span className="text-sm text-gray-400">
          {formState.errors.accountNumber.message?.toString()}
        </span>
      </div>
    );
  }

  if (data) {
    return (
      <div className="flex items-center gap-1">
        <Icon name="check" size={16} className="text-green-500" />
        <span className="text-sm text-gray-400">{data.name}</span>
      </div>
    );
  }

  return null;
};

export const DirectDebitNZAccountNumberField = ({
  useQueryHook,
  ...props
}: {
  useQueryHook: Parameters<
    typeof useDirectDebitRemoteValidationQuery
  >[number]['useQueryHook'];
} & Pick<
  FormInputNumberFormatProps,
  'size' | 'className' | 'label' | '_label'
>) => {
  const {setValue, clearErrors, trigger} = useFormContext();

  const lookup = useDirectDebitRemoteValidationQuery({
    fieldName: 'accountNumber',
    remoteValidatedFieldName: 'accountNumberRemoteValidated',
    region: 'nz',
    useQueryHook,
  });

  return (
    <div className="flex-1 flex flex-col gap-3">
      <DirectDebitNZAccountNumberInput
        {...props}
        onChange={async value => {
          clearErrors(['accountNumber']);
          setValue('accountNumber', value.trim());
          setValue('accountNumberRemoteValidated', false);
          await trigger(['accountNumber', 'accountNumberRemoteValidated']);
        }}
      />
      <AccountNumberCheck isFetching={lookup.isFetching} data={lookup.data} />
    </div>
  );
};
