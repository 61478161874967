import React, {Fragment, useEffect, useRef} from 'react';
import {Button, Loading} from 'payble-ui';
import {Dialog, Transition} from '@headlessui/react';
import {useAPIQuery} from '../../../lib/api';
import {useDisclosure} from '../../../lib/hooks/useDisclosure';
import {useParams} from 'react-router-dom';

interface Props {
  disclosure: ReturnType<typeof useDisclosure>;
}

export const PathwayPreviewModal: React.FC<Props> = ({
  disclosure: {isOpen, onClose},
}) => {
  const cancelButtonRef = useRef(null);
  const {lookup} = useParams<{lookup: string}>();

  if (!lookup) {
    throw new Error(`Account id ${lookup} not found`);
  }

  const pathway = useAPIQuery('getPathwayImageURL', {
    data: {accountId: lookup},
    query: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (isOpen && !pathway.data) {
      pathway.refetch();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="flex flex-row justify-center items-center">
                <div className="mt-4">
                  <Loading className="absolute m-auto left-0 right-0" />
                  {!pathway.isLoading && (
                    <img src={pathway.data?.imageUrl} className="relative" />
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  onClick={onClose}
                  size="sm"
                  className="w-auto"
                  ref={cancelButtonRef}
                >
                  Close
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
