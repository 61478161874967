import {NotificationSettingTable} from 'payble-app-shared';

import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useAPIMutation, useAPIQuery} from 'lib/api';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from '../forms/components/FormHeader';
import {BlockedSidebar} from './BlockedSidebar';

export const NotificationSettingTableForm = ({
  contactId,
  archivedContact,
  disclosure: {isOpen, onClose},
}: {
  contactId: string;
  archivedContact: boolean;
  disclosure: ReturnType<typeof useDisclosure>;
}) => {
  const {mutateAsync: update} = useAPIMutation(
    'updateNotificationSettings',
    {}
  );
  const {
    data: defaultValues,
    dataUpdatedAt,
    isPending,
  } = useAPIQuery('getNotificationSettings', {data: {contactId}});

  if (archivedContact) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Manage notifications"
        headerDescription="Manage notifications settings for this contact."
        headline="Inactive contact"
        text="This contact is archived. Please unarchive the contact before continue."
        icon="ExclamationTriangleIcon"
      />
    );
  }

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <div className="h-full overflow-y-scroll bg-white shadow-xl">
        <FormHeader
          setOpen={onClose}
          title="Manage notifications"
          description="Manage notifications settings for this contact."
        />
        <div className="px-4 py-6">
          <NotificationSettingTable
            defaultValues={defaultValues}
            key={dataUpdatedAt}
            disabled={isPending}
            onSave={groups =>
              update({
                contactId,
                groups,
              })
            }
          />
        </div>
      </div>
    </PopOverSidebar>
  );
};
