import {FC} from 'react';
import {Button, ButtonProps} from './Button';
import {useForm, useFormContext} from '../hooks/useFormContext';

export const FormSubmitButton: FC<ButtonProps> = props => {
  const {submit} = useForm();
  const {formState} = useFormContext();
  const {isValid, isSubmitting, isLoading, isValidating, disabled} = formState;

  return (
    <Button
      disabled={submit.isDisabled || disabled || !isValid}
      isLoading={isSubmitting || isLoading || isValidating}
      {...props}
      type="submit"
    />
  );
};

FormSubmitButton.displayName = 'FormSubmitButton';
