import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';

export const getPathwayImageURL = defineHTTPEndpoint({
  authn: 'GLOBAL_ADMIN',
  operation: 'GET /getPathwayImageURL',
  queryKey: e => ['get-pathway-image-url', e?.accountId],
  requestSchema: z.object({
    accountId: z.string().uuid(),
  }),
  responseSchema: z.object({imageUrl: z.string().url()}),
});
