import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export const useExecuteLocationStateAction = (
  stateKey: string,
  actionMap: Record<string, () => void>,
  options?: {waitFor?: any[]}
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as Record<string, unknown> | null;
  const waitFor = options?.waitFor ?? [true];
  const stateKeyValue = state?.[stateKey];

  useEffect(() => {
    const action =
      typeof stateKeyValue === 'string' && stateKeyValue in actionMap
        ? actionMap[stateKeyValue]
        : null;

    // Ensure the action runs only when all `waitFor` data are done.
    if (action && waitFor.every(Boolean)) {
      // Delay execution to allow components like modals or drawers
      // to mount after data is fetched.
      setTimeout(() => {
        action();

        if (state) {
          navigate(location.pathname, {replace: true, state: null});
        }
      }, 100);
    }
  }, [stateKeyValue, navigate, ...waitFor]);

  return null;
};
